<template>
    <div class="menu-fold">
        <div class="menu-fold__to-fold">
            <div class="menu-fold__to-fold__icon" @click="handleFoldMenu">
                <svg :class="{ 'rotated': !isItemsMenuVisible }"
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.52861 4.86193C7.78896 4.60158 8.21107 4.60158 8.47142 4.86193L12.4714 8.86193C12.7318 9.12228 12.7318 9.54439 12.4714 9.80474C12.2111 10.0651 11.789 10.0651 11.5286 9.80474L8.00001 6.27614L4.47141 9.80474C4.21107 10.0651 3.78896 10.0651 3.52861 9.80474C3.26826 9.54439 3.26826 9.12228 3.52861 8.86193L7.52861 4.86193Z" fill="#5E718D"/>
                </svg>
            </div>
            <div class="menu-fold__to-fold__text_small">
                {{ title }}
            </div>
        </div>
        <div v-if="isItemsMenuVisible"
            class="menu-fold__items">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "MenuListFoldable",
    props: {
        menu: Array,
        title: {
            type: String,
            required: false,
            default: 'СЕКЦИЯ'
        }
    },
    setup(){
        let isItemsMenuVisible = ref(true);
        return{
            isItemsMenuVisible
        }
    },
    methods:{
        handleFoldMenu(){
            this.isItemsMenuVisible = !this.isItemsMenuVisible;
            if(!this.isItemsMenuVisible){
                localStorage.setItem(`isFoldMenuOpen`, false);
            } else {
                localStorage.removeItem(`isFoldMenuOpen`);
            }
        }
    },
    mounted(){
        const isFoldMenuOpen = localStorage.getItem(`isFoldMenuOpen`);
        this.isItemsMenuVisible = !isFoldMenuOpen ? true : false;
    }
}
</script>

<style scoped>
.menu-fold{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.menu-fold__to-fold{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 8px;
}
.menu-fold__to-fold__icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-fold__items{
    display: flex;
    flex-direction: column;
}
.menu-fold__to-fold__text{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--color-state-grey-500);
}
.menu-fold__to-fold__text_small{
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--color-state-grey-500);
}
.menu-fold__to-fold__icon svg{
    transition: transform 0.3s;
}
.rotated {
  transform: rotate(-180deg);
}
</style>