<template>
	<div class="perror">
		<div class="perror__block-error">
			<div class="perror__block-error__er-number">
				<div
					class="perror__block-error__er-number__value font-headline-2-semibold"
				>
					{{ this.getStatusError }} Error
				</div>
				<div class="perror__block-error__er-number__to-copy">
					<ipt-button
						@click="handleCopyTextError"
						:styles="{
							type: 'Link Color',
							size: 'sm',
						}"
					>
						Скопировать код ошибки
						<template v-slot:iconRight>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.8124 11.812H15.1874V2.81201H6.18745V6.18701M2.81238 6.18709H11.8124V15.1871H2.81238V6.18709Z"
									stroke="#1B4DFF"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</template>
					</ipt-button>
				</div>
			</div>
			<div class="perror__block-error__er-icon"></div>
			<div class="perror__block-error__er-panel">
				<div class="perror__block-error__er-panel__message">
					<div
						class="perror__block-error__er-panel__message__title font-headline-6-semibold"
					>
						Возникла ошибка!
					</div>
					<div
						class="perror__block-error__er-panel__message__text font-paragraph-3-medium"
					>
						Отправьте данные разработчикам, чтобы мы могли исправить ошибку
					</div>
				</div>
				<div class="perror__block-error__er-panel__send-er">
					<ipt-button
						v-if="!isSendingMessage"
						@click="handleSendMessage"
						:styles="{
							type: 'Primary',
							size: 'md',
						}"
					>
						Отправить
					</ipt-button>
					<ipt-button
						v-else
						:styles="{
							type: 'Dark Grey',
							size: 'md',
						}"
					>
						Отправлено
						<template v-slot:iconRight>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15.1875 5.06287L7.3125 12.9375L3.375 9.00037"
									stroke="#455468"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</template>
					</ipt-button>
				</div>
			</div>
		</div>
	</div>
	<m-info
		v-if="isOpenInfoModalToSaveChanges"
		:modal="{
			message: this.message,
			nameCancelButton: 'Закрыть',
		}"
		:isModalOpen="isOpenInfoModalToSaveChanges"
		@exit="this.isOpenInfoModalToSaveChanges = false"
	>
	</m-info>
</template>

<script>
import IptButton from "@/components/UI/buttons/IptButton.vue";
import { mapGetters } from "vuex";
import apiConfig from "@/config/api.config.js";
import $api from "@/http/index.js";
import { ref } from "vue";
export default {
	name: "PageError",
	components: {
		IptButton,
	},
	setup() {
		let isSendingMessage = ref(false);
		let isOpenInfoModalToSaveChanges = ref(false);
		let message = ref("");
		return {
			isSendingMessage,
			isOpenInfoModalToSaveChanges,
			message,
		};
	},
	computed: {
		...mapGetters({
			getError: "error/getError",
			getErrorStatus: "error/getErrorStatus",

			getMessageError: "error/getMessageError",
			getStatusError: "error/getStatusError",
		}),
	},
	methods: {
		getTextMessage() {
			const error = this.getError;
			let text = null;
			if (error.isError == false) {
				text = error;
			} else {
				text = error ? error : "Пустой объект ошибки";
			}
			return JSON.stringify(text);
		},
		handleCopyTextError() {
			const text = this.getMessageError;
			this.copyToClipboardFallback(text);
		},
		copyToClipboardFallback(text) {
			const tempTextarea = document.createElement("textarea");
			tempTextarea.value = text;
			document.body.appendChild(tempTextarea);
			tempTextarea.select();
			document.execCommand("copy");
			document.body.removeChild(tempTextarea);
		},
		handleSendMessage() {
			if (this.isSendingMessage) return;
			const text = this.getMessageError;
			const objToSend = {
				uri: apiConfig.API_GATEWAY,
				message: text,
			};
			return this.fetchSendMessageToServer(objToSend)
				.then(() => {
					this.isSendingMessage = true;
					setTimeout(() => {
						this.isSendingMessage = false;
					}, 5000);
				})
				.catch((error) => {
					this.showMessage(
						`При отправке сообщения возникла ошибка\n
                    ${JSON.stringify(error)}`
                );
                return error;
            })
        },
        showMessage(message){
            this.message = message;
            this.isOpenInfoModalToSaveChanges = true;
        },
        fetchSendMessageToServer(objToSend){
            return $api.post(`${apiConfig.API_GATEWAY}/BugReport/PostBugReport`,
                objToSend
            )
            .then((response)=>{return response;})
            .catch(error=>{throw error;});
        }
    }
}
</script>

<style scoped>
.perror {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	/* border: 10px solid red; */
}
.perror__block-error {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* border: 5px solid rgb(4, 255, 0); */
	width: max-content;
}
.perror__block-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
}
.perror__block-error__er-number {
	/* border: 2.5px solid rgb(0, 166, 255); */
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
}
.perror__block-error__er-icon {
	/* border: 2.5px solid rgb(170, 0, 255); */
	background: url("@/assets/svg/Error.svg");
	background-size: contain;
	width: 281px;
	aspect-ratio: 1;
}
.perror__block-error__er-panel {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
}
.perror__block-error__er-panel__message {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
.perror__block-error__er-panel__message__title {
	color: var(--color-state-grey-900);
	white-space: nowrap;
}

.page-error__code-error {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	font-size: 128px;
}
.page-error__text-error {
	width: 75%;
	padding: 20px;
	overflow: scroll;
	height: 45%;
	border: 2px solid var(--ipt-color-warning);
	border-radius: 20px;
}
</style>