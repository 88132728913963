<template>
    <div class="msimple" v-if="isModalActive" @focusout="(e) => handleFocusOut(e)" >
        <div @click="handleCloseFalse" class="mb">
        </div>
        <div class="mw ipt-shadow-2xl">
            <div class="mw__header">
                <div class="mw__header__title font-paragraph-2-medium">
                    {{ modal.title }}
                </div>
                <div class="mw__header__close-button">
                    <ipt-button-cancel-x @click="handleCloseFalse" :width="'20px'" :height="'20px'">
                    </ipt-button-cancel-x>
                </div>
            </div>
            <div class="mv__content">
                <div class="mv__content_loading" v-show="isLoading">
                    <puzzle-loader class="mv__content_loading__loader"></puzzle-loader>
                </div>
                <slot></slot>
            </div>
            <div class="mv__footer">
                <div class="mv__footer__cancel-button">
                    <ipt-button @click="handleCloseFalse" :styles="{
                        type: 'Secondary Grey',
                        size: 'sm',
                        state: isLoading ? 'disabled' : 'default'
                    }">
                        {{ nameCancelButton }}
                    </ipt-button>
                </div>
                <div class="mv__footer__accept-button">
                    <ipt-button @click="handleCloseAcceptable" :styles="{
                        type: 'Primary',
                        size: 'sm',
                        state: isLoading ? 'disabled' : 'default'
                    }">
                        {{ nameAcceptableButton }}
                    </ipt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IptButtonCancelX from '@/components/UI/butons/IptButtonCancelX.vue';
import IptButton from '@/components/UI/buttons/IptButton.vue';
import PuzzleLoader from '@/components/UI/loaders/PuzzleLoader.vue';
import { ref } from 'vue';
export default {
    name: "MWindow",
    components: {
        IptButtonCancelX,
        IptButton,
        PuzzleLoader,
    },
    props: {
        isModalOpen: Boolean,
        isLoading: Boolean,
        modal: {
            title: String,
        },
        nameAcceptableButton: {
            required: false,
            type: String,
            default: 'Применить'
        },
        nameCancelButton: {
            required: false,
            type: String,
            default: 'Отменить'
        }
    },
    emits: ['exit'],
    setup(props) {
        let isModalActive = ref(props.isModalOpen);
        let firstTarget = ref(null);
        return {
            isModalActive, firstTarget
        }
    },
    methods: {
        handleCloseFalse() {
            if(this.isLoading) return;
            this.$emit('exit', {
                status: false
            });
        },
        handleCloseAcceptable() {
            if(this.isLoading) return;
            this.$emit('exit', {
                status: true
            });
        },
        handleFocusOut(e) {
            if (!this.firstTarget) {
                this.firstTarget = e.target;
            }
            const isFocusIn = this.$el.contains(e.relatedTarget)
                || e.relatedTarget === this.$el
                || this.$el.contains(e.target);
            if(!isFocusIn && !e.relatedTarget) {
                this.firstTarget.focus()
            }
        },
    },
    mounted() {
        this.$el.focus()
        const focusableElements = this.$el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusableElements.length > 0) {
            focusableElements[0].focus();
        }
    },
}
</script>

<style scoped>
.mb {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.3;
}

.mw {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    background-color: var(--color-base-00);
    width: 440px;
}

.mw__header,
.mv__content,
.mv__footer {
    width: 100%;
}

.mw__header {
    height: 52px;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid var(--color-state-grey-100);
}

.mv__content {
    padding: 16px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    max-height: 70vh;
    overflow-y: auto;
}
.mv__content_loading{
    opacity: 0.5;
    position: absolute;
    inset: 0;
    cursor: not-allowed;
    user-select: none;
}
.mv__content_loading__loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mw__header__close-button {
    height: fit-content;
}

.mv__footer {
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-top: 1px solid var(--color-state-grey-100);
}
</style>