import $api from "@/http/index.js";
import apiConfig from '@/config/api.config.js';

const inputsHandler = {
    api: apiConfig.API_GATEWAY,

    fetchSaveChanges: (obj) => {
        const value = obj.value;
        const systemGuid = obj.systemGuid;
        const path = obj.path;
        const url = `${apiConfig.API_GATEWAY}/Object/PutValue/${systemGuid}/${path}`;

        return $api.put(url, {
            data: value
        })
        .then(response => (response))
        .catch(e => {
            throw e;
        });
    },
};

export default inputsHandler;