import { createApp } from 'vue'
import App from './App.vue'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import router from '@/router/router';
import store from '@/store';
import GetLoading from '@/components/UI/GetLoading.vue';
import EmptyContent from '@/components/UI/info-components/EmptyContent.vue';
import DrawAttribute from '@/components/systems/systemsBlockSystemInfo/DrawAttribute.vue';
import MInfo from '@/components/UI/modal-window/MInfo.vue';
import ModalWindow from '@/components/UI/modal-window/ModalWindow.vue';

const app = createApp(App);

app
    .component('GetLoading', GetLoading)
    .component('EmptyContent', EmptyContent)
    .component('DrawAttribute', DrawAttribute)
    .component('MInfo', MInfo)
    .component('ModalWindow', ModalWindow)
    .use(store)
    // .use(bootstrap)
    .use(router)
    .mount('#app'); 
