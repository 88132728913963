<template>
    <div class="app">
        <div v-if="isNavBar && !isLoading"
            class="app__navbar">
            <div class="app__navbar__panel-tabs">
                <div class="app__navbar__panel-tabs__header-logo">
                    <logo-iptimizer></logo-iptimizer>
                </div>
                <div class="app__navbar__panel-tabs__tab-menu">
                    <div class="app__navbar__panel-tabs__tab-menu__basic-tabs">
                        <menu-list>
                            <menu-item-logo v-for="it in getDynamicTabs"
                                :path="it.path"
                                :name="it.name"
                                :pathToIcon="it.pathIcon"
                                :key="JSON.stringify(it)"
                            >
                                <template #menuText>
                                    {{ it.label }}
                                </template>
                            </menu-item-logo>
                        </menu-list>
                    </div>
                    <div class="app__navbar__panel-tabs__tab-menu__fold-tabs">
                        <menu-list-foldable>
                            <!-- <menu-item-logo :path="'/archive'">
                                <template #svgIcon>
                                    <div class="container-svg-archieve container-svg_small"></div>
                                </template>
                                <template #menuText>
                                    Архив
                                </template>
                            </menu-item-logo> -->
                            <menu-item-logo :path="'/docs-templates'">
                                <template #svgIcon>
                                    <div class="container-svg-doc-templates container-svg_small"></div>
                                </template>
                                <template #menuText>
                                    Шаблоны документов
                                </template>
                            </menu-item-logo>
                        </menu-list-foldable>
                        <menu-item-logo v-for="it in getStaticTabs"
                                :path="it.pathToNavigate"
                                :name="it.name"
                                :pathToIcon="it.name"
                                :key="JSON.stringify(it)"
                            >
                            <template #menuText>
                                {{ it.label }}
                            </template>
                        </menu-item-logo>
                    </div>
                </div>
            </div>
            <div class="app__navbar__footer">
                <div class="app__navbar__footer__tabs">
                    <menu-list>
                        <menu-item-logo :path="'/help'">
                            <template #svgIcon>
                                <div class="container-svg-help container-svg_small"></div>
                            </template>
                            <template #menuText>
                                Помощь
                            </template>
                        </menu-item-logo>
                        <menu-item-logo :path="'/about'">
                            <template #svgIcon>
                                <div class="container-svg-about container-svg_small"></div>
                            </template>
                            <template #menuText>
                                Инструкция
                            </template>
                        </menu-item-logo>
                    </menu-list>
                </div>
                <div class="app__navbar__footer__user-section">
                    <user-profile></user-profile>
                </div>
            </div>
        </div>
        <div class="app__content"
            v-if="!isLoading">
            <RouterView />
        </div>
        <div class="app_loading" v-if="isLoading">
            <get-loading></get-loading>
        </div>
    </div>
</template>

<script>
// import PageError from '@/pages/PageError.vue';
import LogoIptimizer from '@/components/UI/logo-panel/LogoIptimizer.vue';
import MenuItemLogo from '@/components/navbar/MenuItemLogo.vue';
import MenuList from '@/components/navbar/MenuList.vue';
import MenuListFoldable from '@/components/navbar/MenuListFoldable.vue';
import AuthService from '@/services/AuthService';
import UserProfile from '@/components/user/UserProfile.vue';

import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'App',
    components: {
        LogoIptimizer,
        MenuItemLogo,
        MenuList,
        MenuListFoldable,
        UserProfile,
    },
    setup(){
        let isFactoryMode = ref(null);
        let isLoading = ref(false);
        return{
            isFactoryMode,
            isLoading
        }
    },
    watch:{
        $route(to){
            if(to.fullPath != '/')
                localStorage.setItem('lastPage', to.fullPath);
        }
    },
    computed:{
        ...mapGetters({
            getDynamicTabs: 'roleAndAuth/getDynamicTabs',
            getStaticTabs: 'roleAndAuth/getStaticTabs'
        }),
        isNavBar(){
            return this.$route.name !== 'PageAuth';
        },
        isHelpMode(){
            return process.env.VUE_APP_ISHELP == 'true';
        },
    },
    methods: {
        ...mapActions({
            setUserRoleConfig: 'roleAndAuth/setUserRoleConfig',
            setMessageError: 'error/setMessageError',
        }),
    },
    async mounted(){
        this.isLoading = true;
        await AuthService.getUserProfile()
        .then((response)=>{
            this.setUserRoleConfig(response.data);
            if(this.$router.currentRoute.value.fullPath == '/'){
                this.$router.push(
                    localStorage.getItem('lastPage')
                );
            } else {
            this.$router.push(this.$router.currentRoute.value.fullPath);
            }
            console.info('userRoleObject: ', response.data);
        })
        .catch(()=>{
            this.$router.push('/auth');
        })
        .finally(()=>{
            this.isLoading = false;
        });
    },
}
</script>
<style>
/* вспомогательные стили */
@import url("@/styles/index.css");
/* тени */
@import url("@/styles/shadows/shadows.css");
/* шрифты */
@import url("@/styles/fonts/fonts-typography/headline.css");
@import url("@/styles/fonts/fonts-typography/body-text.css");
@import url("@/styles/fonts/fonts-typography/short-description.css");
/* скролл */
@import url("@/styles/scroll/scroll-fat.css");
@import url("@/styles/scroll/scroll-thin.css");
/* цвета */
@import url("@/styles/colors/colors.css");
@font-face {
    font-family: 'Montserrat-Medium';
    src: url('@/assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    src: url('@/assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
    font-size: 16px;
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('@/assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-size: 12px !important;
}
:root {
    --ipt-active-element-color: #A5D4FF;

    --ipt-button-color: var(--ipt-active-element-color);
    --ipt-button-text-color: #260E58;
    --ipt-background-color: #FFFFFF;
    --ipt-color-warning: #7D000F;
    --ipt-color-acceptable: #256207;
    --ipt-color-titles: #0E0F0E;

    --ipt-hover-element-text-color: #FFFFFF;
    --ipt-hover-element-background-color: #2A4FAF;

    --ipt-border-color: #260E58;
    --ipt-border-lite-color: #D0CAEA;

    --ipt-hover-border-color: #605C90;
    --ipt-hover-text-color: #260E58;
    --ipt-active-border-color: #256207;

    --ipt-title-color: #0E0F0E;
    --ipt-subtitle-color: #260E58;

    --ipt-default-element-color: #0E0F0E;
    --ipt-default-active-color: #605C90;

    --ipt-noactive-color: #ebe9e9;
    --ipt-noactive-text-color: #0E0F0E;
    --ipt-noactive-text-color: #FFFFFF;
}


body,
html {
    margin: 0;
    padding: 0;

    height: 100vh;
    width: 100vw;
    background: var(--ipt-background-color);

    scrollbar-width: 2px;
    scrollbar-color: rgba(0,0,0,.5);
    background-attachment: fixed;
}

.app{
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* border: 1px solid rgb(0, 255, 204); */
}
.app_loading{
    position: absolute;
    inset: 0;
}
.app__navbar{
    height: 100%;
    min-width: 200px;
    max-width: 200px;
    position: relative;
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-state-grey-50);
    border-right: 1px solid var(--color-state-grey-200);
    /* border: 10px solid red; */
}
.app__navbar__panel-tabs{
    position: relative;
    width: 100%;
    /* border: 5px solid rgb(0, 255, 76); */
}
.app__navbar__panel-tabs{
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.app__navbar__panel-tabs__tab-menu{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.app__navbar__panel-tabs__header-logo{
    position: relative;
}
.app__navbar__footer{
    /* border: 5px solid rgb(174, 255, 0); */
    position: relative;
}
.app__navbar__footer__user-section{
    position: relative;
}
.app__content {
    flex: 1;
    /* border: 1px solid rgb(17, 0, 255); */
    overflow: hidden;
}
.container-svg{
    width: 24px;
    height: 24px;
}
.container-svg_small{
    width: 16px;
    aspect-ratio: 1;
    background-size: contain;
}
.container-svg-about{
    background: url(/public/resource/navbar-icons/About_BookOpen.svg);
    background-size: contain;
}
/* .container-svg-company{
    background: url(/public/resource/navbar-icons/Company_Buildings.svg);
    background-size: contain;
}
.container-svg-system{
    background: url(@/assets/icons-navbar/tab-system.svg);
    background-size: contain;
} */
.container-svg-sandbox{
    background: url(@/assets/icons-navbar/tab-sandbox.svg);
    background-size: contain;
}
.container-svg-reports{
    background: url(@/assets/icons-navbar/tab-reports.svg);
    background-size: contain;
}
.container-svg-archieve{
    background: url(@/assets/icons-navbar/tab-archieve.svg);
    background-size: contain;
}
.container-svg-doc-templates{
    background: url(@/assets/icons-navbar/tab-doc-templates.svg);
    background-size: contain;
}
.container-svg-help{
    background: url(@/assets/icons-navbar/tab-help.svg);
    background-size: contain;
}
.container-svg-factory{
    background: url(@/assets/icons-navbar/tab-factory.svg);
    background-size: contain;
}
</style>