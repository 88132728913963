<template>
    <div tabindex="1" @focusout="(e) => handleFocusOut(e)" 
        class="minfo">
        <div class="mb-info">
        </div>
        <div class="mw-info scroll-bar-grey-thin">
            <div class="mw-info__content">
                <div class="mw-info__content__message font-paragraph-3-semibold">
                    {{ modal.message }}
                </div>
                <div class="mw-info__content__panel">
                    <div class="mw-info__content__panel__cancel-button panel-button">
                        <ipt-button @click="$emit('exit')" :styles="{
                            type: 'Secondary Grey',
                            size: 'sm'
                        }">
                            {{ modal.nameCancelButton }}
                        </ipt-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IptButton from '@/components/UI/buttons/IptButton.vue';
import { ref } from 'vue';
export default {
    name: "MInfo",
    components: {
        IptButton
    },
    props: {
        modal: {
            message: String,
            nameCancelButton: {
                type: String,
                required: false,
                default: 'Закрыть'
            },
        },
        isModalOpen: Boolean
    },
    setup() {
        let firstTarget = ref(null);
        return {
            firstTarget
        }
    },
    methods: {
        handleFocusOut(e) {
            if (!this.firstTarget) {
                this.firstTarget = e.target;
            }
            if (!(this.$el.contains(e.relatedTarget) || e.relatedTarget === this.$el) || !e.relatedTarget) {
                this.firstTarget.focus()
            }
        },
    },
    mounted() {
        this.$el.focus()
        const focusableElements = this.$el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusableElements.length > 0) {
            focusableElements[0].focus();
        }
    },
}

</script>

<style scoped>
.minfo {
    position: fixed;
    inset: 0 !important;
    z-index: 2999;
}
.mb-info {
    z-index: 3000;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.3;
}

.mw-info {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 75vh;
    overflow-y: auto;
    z-index: 3001;
    background-color: var(--color-base-00);
    box-shadow: 7px 12px 64px -12px rgba(45, 54, 67, 0.45);
    padding: 24px;
    border-radius: 6px;
}

.mw-info__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 400px;
}

.mw-info__content__panel {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.mw-info__content__message {
    white-space: pre-line;
}


.minfo-enter-from {
    opacity: 0;
}

.minfo-leave-to {
    opacity: 1;
}

.minfo-enter-active,
.minfo-leave-active {
    transition: opacity 0.2s ease;
}

.minfo-enter-from,
.minfo-leave-to {
    opacity: 0;
}
</style>