<template>
    <router-link :to="{ path: path }" class="text-decoration-none">
        <div @click="addStylesToActiveMenu" :class="this.$route.path.split('/')[1] == this.path.split('/')[1]
                ? 'menu-item_active' : ''
            " class="menu-item_small">
            <div class="menu-item__logo">
                <slot name="svgIcon" v-if="!pathToIcon">
                </slot>
                <div class="menu-item__logo__content" v-else
                :class="getClassIcon">
                    
                </div>
                
            </div>
            <div class="menu-item__text font-paragraph-6-medium">
                <slot name="menuText"></slot>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "MenuItemLogo",
    props: {
        path: String,
        pathToIcon: String,
        name: String,
    },
    data(){
        const mapNameToClassNameIcon = {
            "Organization": "container-svg-organization",
            "System": "container-svg-system",
            "sandbox": "container-svg-sandbox",
            "factory": "container-svg-factory",
            "PageGeneralReports" : "container-svg-reports",
            "administration": "container-svg-administration",
        }
        return {mapNameToClassNameIcon}
        
    },
    computed: {
        getClassIcon(){
            return this.mapNameToClassNameIcon[this.name] || 'container-svg-default';
        }
    },
    mounted() {
        if (!this.pathToIcon) return;
        // const iconDiv = document.querySelectorAll('.menu-item__logo')[0];
        // iconDiv.style.background = `url(${this.pathToIcon})`;
        // iconDiv.style.backgroundSize = 'contain';
    },
}
</script>

<style scoped>
router-link {
    text-decoration: none;
}

.menu-item_small {
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    min-width: 184px;
}

.menu-item {
    cursor: pointer;
    padding: 14px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.menu-item:hover {
    background-color: var(--color-state-grey-100);
}

.menu-item_active {
    background-color: var(--color-state-grey-200);
}

.menu-item__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.menu-item__logo__content{
    /* background: url("@/assets/icons-navbar/tab-System.svg");  */
    
    background-size: contain;
    width: 16px; 
    aspect-ratio: 1 / 1;
}
.menu-item__text {
    color: var(--color-state-grey-700);
    white-space: nowrap;
}
.container-svg-default{
    background: url(@/assets/icons-navbar/tab-default.svg) 0 0 / contain no-repeat;
}
.container-svg-administration{
    background: url(@/assets/icons-navbar/tab-administration.svg);
    background-size: contain;
}
.container-svg-organization{
    background: url(/public/resource/navbar-icons/Company_Buildings.svg);
    background-size: contain;
}
.container-svg-system{
    background: url(@/assets/icons-navbar/tab-system.svg);
    background-size: contain;
}
.container-svg-sandbox{
    background: url(@/assets/icons-navbar/tab-sandbox.svg);
    background-size: contain;
}
.container-svg-reports{
    background: url(@/assets/icons-navbar/tab-reports.svg);
    background-size: contain;
}
.container-svg-archieve{
    background: url(@/assets/icons-navbar/tab-archieve.svg);
    background-size: contain;
}
.container-svg-doc-templates{
    background: url(@/assets/icons-navbar/tab-doc-templates.svg);
    background-size: contain;
}
.container-svg-help{
    background: url(@/assets/icons-navbar/tab-help.svg);
    background-size: contain;
}
.container-svg-factory{
    background: url(@/assets/icons-navbar/tab-factory.svg);
    background-size: contain;
}
</style>