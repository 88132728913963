<template>
    <div class="psettings">
        <header class="psettings__title-page font-paragraph-3-medium">
            Об Iptimizer
        </header>
        <main class="psettings__content">
            
            <div class="psettings__content__instruction">
                <div class="psettings__content__instruction__content">
                    <div class="psettings__content__instruction__content__text">
                        Инструкция по работе <br>
                        Iptimizer
                    </div>
                    <div class="psettings__content__instruction__content__button">
                        <ipt-button @click="handleDownloadInstruction"
                            :styles="{
                                type: 'Primary',
                                size: 'sm'
                            }">
                            Скачать инструкцию
                        </ipt-button>
                    </div>
                </div>
                <div class="psettings__content__instruction__image">
                    <img height="184px" width="256px" src="/resource/instruction/helpCircle.png">
                </div>
            </div>
            <div class="psettings__content__notes">
                <iframe src="/resource/release/IPT_release_notes.html" width="100%" height="100%"></iframe>
            </div>
        </main>
    </div>
</template>
<script>
import IptButton from '@/components/UI/buttons/IptButton.vue';
// import SimpleInput from '@/components/UI/typesInputs/SimpleInput.vue';
// import NameInput from '@/components/UI/typesInputs/NameInput.vue'
// import filesFromServe from '@/myLib/filesFromServe.js'
export default {
    name: "PageSettings",
    components:{
        IptButton
    },
    methods:{ 
        handleDownloadInstruction(){
            const fileName = '/resource/instruction/Инструкция_Iptimizer.pdf';
            var element = document.createElement('a');
            element.setAttribute('href', fileName);
            element.setAttribute('download', 'Иптимайзер - Инструкция по эксплутации.pdf');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }
}
</script>

<style scoped>
.psettings {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.psettings__title-page{
    width: 100%;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    background-color: var(--color-base-00);
    border-bottom: 1px solid var(--color-state-grey-200);
}
.psettings__content{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    flex: 1;
}
.psettings__content__instruction{
    background-color: var(--color-primary-blue-25);
    overflow: hidden;
    border-radius: 12px;
    height: 191px;
    min-width: 585px;
    max-width: 754px;
    width: 100%;
    position: relative;
}
.psettings__content__instruction__content{
    position: absolute;
    top: 20.5px;
    left: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 247px;
}
.psettings__content__instruction__image{
    position: absolute;
    top: 15px;
    right: 51px;
    height: fit-content;
    width: fit-content;
}
.psettings__content__instruction__content__text{
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 800;
    line-height: 33px;
    letter-spacing: -0.3px;
    text-align: left;
    color: var(--color-state-grey-900);
    white-space: nowrap;
}
.psettings__content__instruction__content__button{
    width: 173px;
    white-space: nowrap;
}
.psettings__content__notes{
    width: 100%;
    position: relative;
    flex: 1;
}
</style>