<template>
    <div class="uspf user-profile">
        <div class="uspf__block uspf__data">
            <div class="uspf__data__avatar uspf__avatar">
                <label class="uspf__data__avatar__bg" for="user-avatar-photo-icon">
                    <input :key="this.srcAvatar" class="uspf__data__avatar__bg__file" type="file" name=""
                        id="user-avatar-photo-icon" @input="handleUploadingAvatar($event)" accept=".jpg, .png">
                </label>
            </div>
            <div class="uspf__data__content">
                <div class="uspf__data__content__name-role font-paragraph-6-medium">{{ nameRole }}</div>
                <div class="uspf__data__content__email font-paragraph-6-regular">{{ email }}</div>
            </div>
        </div>
        <div class="uspf__block uspf__info">
            <div class="exit font-paragraph-6-regular" @click="handleLogout">Выйти</div>
        </div>
    </div>
</template>
<style scoped>
.uspf__info:hover .exit{
    display: block;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
}
.exit{
    display: none;
    background-color: var(--color-state-grey-100);
    color: var(--color-error-red-800);
    border: 1px solid var(--color-error-red-800);
    width: fit-content;
    padding: 3px 8px;
    border-radius: 5px;
    cursor: pointer;
}
.exit:hover{
    background-color: var(--color-error-red-800);
    color: var(--color-base-00);
}
</style>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'UserProfile',
    props: {
        srcAvatar: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters({
            getUserRoleConfig: 'roleAndAuth/getUserRoleConfig'
        }),
        nameRole() {
            return this.getUserRoleConfig?.name || 'пусто';
        },
        email(){
            return this.getUserRoleConfig?.email || 'пусто';
        },
    },
    methods: {
        handleLogout() {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('profileGuid');
            this.$router.push('/auth');
        },
    }
}
</script>

<style scoped>
:root{
    --height-profile: 84px;
}
.uspf{
    position: relative;
    background-color: var(--color-state-grey-100);
    width: 100%;
    height: var(--height-profile);
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    overflow: hidden;
}
.uspf__info{
    min-width: 15px;
    min-height: 15px;
    position: relative;
    cursor: pointer;
    background-image: url('@/assets/icons-navbar/user-profile-icon-info.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.uspf__data{
    overflow: hidden;
    position: relative;
    flex: 1;
    display: flex;
    gap: 8px;
    align-items: center;
}
.uspf__data__content{
    flex: 1;
    overflow: hidden;
}
.uspf__data__content__name-role{
    color: var(--color-state-grey-700);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.uspf__data__content__email{
    color: var(--color-state-grey-500);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.uspf__data__avatar{
    min-width: 20px;
    width: 20px;
    aspect-ratio: 1;
    position: relative;
    display: none;
}
.uspf__data__avatar__bg{
    cursor: pointer;
    position: absolute;
    inset: 0;
    background-color: var(--color-primary-blue-25);
    background-image: url('@/assets/icons-navbar/user-profile-default-photo-user.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    overflow: hidden;
}
.uspf__data__avatar__bg__file{
    opacity: 0;
    position: absolute;
    inset: 0;
    width: 0.1px;
    height: 0.1px;
    z-index: -1;
    cursor: pointer;
}</style>