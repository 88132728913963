import apiConfig from "@/config/api.config.js";
import $api from "@/http";
import {ValidationObject} from '@/myLib/validationFields'
export default class ObjectService extends ValidationObject{ //получить объекты 
    static apiPrefix = apiConfig.API_GATEWAY
    static GetObjects(obj) {
        const allowedParams = ['ExtremumDateTime', 'Down', 'Size', 'type', 'sort', 'search', 'filter'];
        const params = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetObjects`, 
            { params }
        )
    }
    static GetSearchObjects(obj){
        const allowedParams = ['type', 'search', 'Size'];
        const query = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetSearchObjects`, 
            { params: query }
        )
    }
    static CreateObject(obj){
        const allowedParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.post(`${ObjectService.apiPrefix}/Object/CreateObject`, 
            { parameters: obj.parameters }, 
            { params: query }
        )
    }
    static ExpressionsAdd(obj){
        const queryParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        const allowedParams = ['searchBy', 'comparisons'];
        const objBody = ObjectService.getObjectByValidKeys(allowedParams, obj);
        objBody.searchBy = JSON.stringify(objBody.searchBy);
        objBody["comparisons"] = objBody.comparisons.map(it=>{
            it.value = `"${it.value}"`;
            // it.value = it.value.replaceAll('/', '\\');
            const obj = {
                "path": it.path,
                "value": it.value,
                "operator": it.operator
            }
            return obj;
        });
        if(objBody.comparisons.length == 0){
            delete objBody.comparisons;
        }
        return $api.post(`${ObjectService.apiPrefix}/Object/ExpressionsAdd`,
            objBody,
            { params: query }
        )
    }
    static ExpressionsClear(obj){
        const queryParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        return $api.delete(`${ObjectService.apiPrefix}/Object/ExpressionsClear`,
            { params: query }
        )
    }
    static GetExpressions(obj){
        const allowedHeaders = ['type'];
        const headers = ObjectService.getObjectByValidKeys(allowedHeaders, obj);
        const queryParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetExpressions`, {
            headers: headers,
            params: query
        })
    }
}