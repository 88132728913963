<template>
    <div v-on:focusout="handleFocusOut" 
        class="template-latex" tabindex="1"
        :class="[
            {'template-latex_edit': stateInput.isInputInEditing},
            {'template-latex_read': !stateInput.isInputInEditing},
        ]">
        <div class="simple-latex font-paragraph-5-regular">
            <div class="simple-textarea__value" @click="handleSetEditMode($event)">
                <textarea v-if="obj.isEditable"
                    :class="[
                        {'requiredToFilling': obj.isRequiredToFilling && (!obj.items)},
                    ]"
                    :readonly="!stateInput.isInputInEditing"
                    type="text" class="simple-textarea__value__field"
                    v-model="this.value"
                    @mousedown="handleMouseDown"
                    placeholder="Введите значение"
                    ref="textareaField"
                    >
                </textarea>
                <textarea v-else
                    readonly
                    type="text" class="simple-textarea__value__field_readonly"
                    :value="obj.items||'пусто'"
                    @mousedown="handleMouseDown"
                    ref="textareaField"
                    >
                </textarea>
                <div v-if="!stateInput.isInputInEditing && obj.isEditable"
                    class="simple-textarea__value__button">
                    <icon-button class="right-icon-in-latex"
                        :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                        <template v-slot="{ width, height }">
                            <svg :width=width :height="height" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.625 5.00015L15 9.37515M7.5 16.8751H3.75C3.58424 16.8751 3.42527 16.8093 3.30806 16.6921C3.19085 16.5749 3.125 16.4159 3.125 16.2501V12.759C3.125 12.677 3.14117 12.5957 3.17258 12.5199C3.20398 12.444 3.25002 12.3751 3.30806 12.3171L12.6831 2.94209C12.8003 2.82488 12.9592 2.75903 13.125 2.75903C13.2908 2.75903 13.4497 2.82488 13.5669 2.94209L17.0581 6.43321C17.1753 6.55042 17.2411 6.70939 17.2411 6.87515C17.2411 7.04091 17.1753 7.19988 17.0581 7.31709L7.5 16.8751Z"
                                    stroke="#5E718D" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>
                    </icon-button>
                </div>
            </div>
        </div>
        <div v-if="stateInput.isInputInEditing" class="simple-latex-edit-buttons">
            <div class="simple-latex-edit-buttons__buttons">
                <icon-button @click="handleSavingChanges"
                    :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                    <template v-slot="{ width, height }">
                        <svg :fill="'none'" :width="width" :height="height" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.875 5.62537L8.125 14.375L3.75 10.0004" stroke="#5E718D" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
                <icon-button @click="handleCancelChanges"
                    :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                    <template v-slot="{ width, height, fill }">
                        <svg :width="width" :height="height" :fill="fill" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.625 4.375L4.375 15.625M15.625 15.625L4.375 4.375" stroke="#5E718D"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
            </div>
        </div>
        <div v-else
            class="latex-panel">
            <div class="latex-panel__status">
                <div class="latex_panel__status__color"
                    :class="this.getClassFromStateCode(this.statusCode)"
                    :title="getErrorMessageFromStateCode()">
                </div>
                <div class="latex-panel__status__text font-paragraph-6-medium"
                    :title="getErrorMessageFromStateCode()">
                    {{ this.getNameFromStateCode(this.statusCode) }}
                </div>
            </div>
            <div class="latex-panel__buttons">
                <ipt-button @click="handleCheckStatusLatex"
                    :styles="{
                    type: 'Secondary Grey',
                    size: 'xsm',
                }"
                    :isLoading="isLoadingStatus">
                    Проверить Latex-код
                </ipt-button>
                <ipt-button @click="handleDownloadPDF"
                    :styles="{
                    type: 'Primary',
                    size: 'xsm',
                    state: this.statusCode !== 'done' ? 'disabled': ''
                }"
                    :isLoading="isLoadingPDF"
                    >
                    Получить PDF
                </ipt-button>
            </div>
        </div>
    </div>
</template>
<style>
.requiredToFilling{
    background-color: var(--color-error-red-25);
}
.latex-panel{
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: space-between;
}
.latex-panel__status{
    display: flex;
    gap: 8px;
    align-items: center;
}
.latex-panel__status__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.latex-panel__buttons{
    display: flex;
    gap: 12px;
}
.latex_panel__status__color {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}
</style>

<script>
import IconButton from '@/components/UI/buttons/icon-buttons/IconButton.vue';
import IptButton from '@/components/UI/buttons/IptButton.vue'
import { ref } from 'vue';
export default {
    name: "SimpleTextarea",
    components: {
        IconButton,
        IptButton,
    },
    props: {
        obj: {
            label: String,
            items: String,
            path: String,
        },
        state: {
            disabled: Boolean,
            default: false,
        },
        isLoadingPDF: {
            type: Boolean,
            default: false,
        },
        isLoadingStatus: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['saveNewValue', 'eventHandler', 'downloadPDF', 'checkLatexStatus'],
    setup(props) {
        const stateInput = ref({
            isInputLoading: false,
            isInputValueCorrect: true,
            isInputInEditing: false,
        });
        let value = ref(props?.obj?.items);
        let oldValue = ref(props?.obj?.items);
        let isInputWasClicked = ref(false);
        let isCanceled = ref(false);
        const textareaField = ref(null);
        const mapStateCodeToClass = ref({
            "errorService": 'status-technical-error',
            "noneValue" : 'status-no-checking',
            "inProgress": 'status-in-checking',
            "errorFields": 'status-error-fields',
            "errorLatex" : 'status-error-latex',
            "done": 'status-done'
        });
        const mapStateCodeToName = ref({
            "errorService": 'Техническая ошибка',
            "noneValue": 'Не проверялось',
            "inProgress": 'Проверяется',
            "errorFields": 'Ошибка Полей',
            "errorLatex": 'Ошибка LaTeX',
            "done": 'Готово к сборке',
        });
        let statusCode = ref(props.obj.status);
        const textArea = ref({
            target: null
        })
        return {
            stateInput, value, oldValue, 
            isInputWasClicked, isCanceled, textareaField,
            mapStateCodeToClass, mapStateCodeToName, statusCode,
            textArea
        }
    },
    methods: {
        getClassFromStateCode(stateCode) {
            return this.mapStateCodeToClass[stateCode] ? this.mapStateCodeToClass[stateCode] : this.mapStateCodeToClass['errorService'];
        },
        getNameFromStateCode(stateCode) {
            return this.mapStateCodeToName[stateCode] ? this.mapStateCodeToName[stateCode] : this.mapStateCodeToName['errorService'];
        },
        getErrorMessageFromStateCode() { 
            return JSON.stringify(this.obj.error).replace(/\\\\/g, '\\')  // Сначала заменяем \\ на \
                .replaceAll(/\\u{([\da-f]{1,6})}|\\u([\da-f]{4})/gi, (_, p1, p2) => 
                    String.fromCodePoint(Number.parseInt(p1 ?? p2, 16))
                ) 
                || 'Нет сообщения';
        },
        eventHandler(from, obj, message){
            const description = `[${Date.now()}] [LOG] [SimpleTextarea:${from}] [объект ${obj ? JSON.stringify(obj) : 'не обрабатывался'} сообщение: ${message ? message : 'отсутствует'}]`;
            this.$emit('eventHandler', {description: description, value: obj});
        },
        handleCheckStatusLatex(){
            this.$emit('checkLatexStatus');
        },
        handleDownloadPDF(){
            if(this.statusCode !== 'done') return;
            this.$emit('downloadPDF');
        },
        handleMouseDown(e){
            console.log(e);
            
            document.addEventListener('mouseup', this.handleMouseUp);
            this.textArea.target = e.target;
        },
        handleSaveHeightFromTarget(target){
            let heightTextareaFromLStorage = localStorage.getItem(`${this.$route.params.id}|${this.obj.path}|textareaHeight`);
            let heightTextareaFact = target.style.height;
            if(heightTextareaFromLStorage !== heightTextareaFact){
                localStorage.setItem(`${this.$route.params.id}|${this.obj.path}|textareaHeight`, heightTextareaFact);
                this.eventHandler('handleMouseUp', null, 'Изменен размер поля');
            }
        },
        handleMouseUp(){
            this.handleSaveHeightFromTarget(this.textArea.target);
            document.removeEventListener('mouseup', this.handleMouseUp);
        },
        handleSetEditMode() {
            if(!this.obj.isEditable) return;
            this.textareaField.focus();
            this.stateInput.isInputInEditing = true;
            this.eventHandler('handleSetEditMode', null, 'Включен режим редактирования');
        },
        handleSavingChanges() {
            this.stateInput.isInputInEditing = false;
            if(this.oldValue === this.value) return;
            this.oldValue = this.value;
            this.$emit('saveNewValue', this.value);
            this.statusCode = 'inProgress';
            this.eventHandler('handleSavingChanges', this.value, 'Изменения отправлены на сохранение');
        },
        handleCancelChanges() {
            this.value = this.oldValue;
            this.stateInput.isInputInEditing = false;
            this.$emit('eventHandler', this.value);
            this.eventHandler('handleCancelChanges', null, 'Изменения значения отменены');
        },
        handleFocusOut(event) {
            if (this.$el.contains(event.relatedTarget)) 
            {
                this.eventHandler('handleFocusOut', null, 'Нажатие внутри компоненты');
            } else {
                this.handleSavingChanges();
                this.eventHandler('handleFocusOut', null, 'Нажатие вне компоненты');
            }
        },
    },
    mounted() {
        let heightTextarea = localStorage.getItem(`${this.$route.params.id}|${this.obj.path}|textareaHeight`);
        this.textareaField.style.height = heightTextarea ? heightTextarea : '80px';
    },
}
</script>

<style scoped>
textarea{
    font-variant-ligatures: none;
}
.simple-textarea__value__field_readonly{
    border: none;
    outline: none;
    width: 100%;
    min-height: 40px;
    padding: 1.5px 8px;
    background-color:rgba(249, 250, 251, 1);
    border-bottom: 1px solid var(--color-state-grey-300);
    color: var(--color-state-grey-300);
    cursor: not-allowed;
}
.requiredToFilling{
    background-color: var(--color-error-red-25);
}
.not-required{
    background-color: var(--color-state-grey-25);
}
.template-latex{
    width: 100%;
    height: fit-content;
    position: relative;
}
.template-latex_read{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
}
.template-latex_edit{
    margin-bottom: 52px;
    padding-bottom: 0px;
}
.simple-latex {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0px 0px;
}
.simple-latex-edit-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0px, 42px);
    height: 36px;
    padding: 0px;
    z-index: 2001;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.simple-latex-edit-buttons__buttons {
    display: flex;
    gap: 6px;
}

.simple-textarea__value {
    width: 100%;
    height: 100%;
    display: flex;
    /* position: relative; */
}

.simple-textarea__value__field {
    width: 100%;
    height: 100%;
    min-height: 80px;
    border: none;
    outline: none;
    background: transparent;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    padding: 1.5px 8px;
    color: var(--color-state-grey-900);
    background-color: rgba(249, 250, 251, 1);
}
.simple-textarea__value__field::placeholder{
    color: var(--color-state-grey-400);
}
.simple-textarea__value:hover > .simple-textarea__value__field{
    border-bottom: 1px solid var(--color-state-grey-300);
    background-color: rgba(249, 250, 251, 1);
    border-radius: 4px;
}
.simple-textarea__value:hover > .simple-textarea__value__field:not(:read-only){
    border-radius: 4px;
}
.simple-textarea__value__field:focus, .simple-textarea__value__field:read-write{
    border-bottom: 1px solid var(--color-state-grey-500);
    background-color: var(--color-state-grey-25);
    border-radius: 4px;
}
.simple-textarea__value:read-only{
    border: 0px;
}
.simple-textarea__value__button{
    display: none;
    position: absolute;
    top: 4px;
    right: 4px;
}
.simple-textarea__value:hover > .simple-textarea__value__button {
    display: block;
}
.simple-textarea__value:hover > .simple-textarea__value__field {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.simple-textarea__value__field__textarea {
    width: 100%;
    height: 100%;
    text-decoration: none;
}
.status-technical-error{
    background-color: var(--color-error-red-800);
}
.status-no-checking{
    background-color: var(--color-warning-yellow-500);
}
.status-in-checking{
    background-color: var(--color-primary-blue-500);
}
.status-error-fields{
    background-color: var(--color-error-red-800);
}
.status-error-latex{
    background-color: var(--color-error-red-800);
}
.status-done{
    background-color: var(--color-success-green-600);
}
.requiredToFilling{
    background-color: var(--color-error-red-25);
}
.not-required{
    background-color: var(--color-state-grey-25);
}
</style>