<template>
    <div class="ctext" @click="copyToClipboard">
        <div class="ctext__label font-paragraph-5-medium">
            {{ textMessage }}
        </div>
        <div v-if="!isTextCopied"
            class="ctext__icon">
            <svg class="svg-to-copy" width="18" height="19" viewBox="0 0 18 19" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path class="svg-path-to-copy"
                d="M11.8126 12.2192H15.1876V3.21924H6.18757V6.59424M2.8125 6.59432H11.8125V15.5943H2.8125V6.59432Z"
                stroke="var(--color-primary-blue-500)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "CopyDocText",
    props: {
        label: String,
        items: String,
    },
    setup(){
        let isTextCopied = ref(false);
        return{
            isTextCopied
        }
    },
    computed:{
        textMessage(){
            return !this.isTextCopied?this.label: 'Скопировано в буфер обмена!'
        }
    },
    methods:{
        copyToClipboard() {
            const text = this.items;
            this.copyToClipboardFallback(text);
            this.isTextCopied = true;
            setTimeout(()=>{
                this.isTextCopied = false;
            }, 2000);
        },
        copyToClipboardFallback(text) {
            const tempTextarea = document.createElement('textarea');
            tempTextarea.value = text;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextarea);
        },
    }
}
</script>

<style scoped>
.ctext{
    cursor: pointer;
    position: relative;
    display: flex;
    gap: 8px;
    width: 100%;
    color: var(--color-primary-blue-500);
}
</style>