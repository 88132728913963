<template>
    <div class="logo">
    </div>
</template>

<script>
export default {
    name: "LogoIptimizer",
}
</script>

<style scoped>
@font-face {
    font-family: 'Poppins';
    src:
        url('@/assets/fonts/Poppins/Poppins-Medium.ttf'),
}
.logo{
    background-image: url('@/assets/logo/ipt-logo-small.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    width: 118px;
    height: 27px;
}
.logo__icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo__text{
    color: var(--colot-state-grey-900);
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}
</style>