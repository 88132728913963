import apiConfig from '@/config/api.config.js';
import $api from "@/http/index.js";

export const reportsModule = {
    state: () => ({
        api: apiConfig.API_GATEWAY_REPORTS,
        id: '',
        statePage:{
            isReadOnly: true,
            isLoading: false,
            isError: false,
        },
        activeReports:{ },
        editingReports:{ },
        error:{ }
    }),
    getters: {
        getActiveReports: (state) => {
            return state.activeReports;
        },
        getEditingReports: (state) => {
            return state.editingReports;
        },
        getTemplateOfElementCollection: (state) => (objChanges) => {
            let editingReports = state.editingReports;
            let temp = editingReports;
            let i = 0;
            for(; i < objChanges.index.length-1; i++){ 
                temp = temp.items[objChanges.index[i]]; 
            } 
            if(objChanges.index.length == 0){
                return JSON.parse(JSON.stringify(temp.template));
            }
            return JSON.parse(JSON.stringify(temp.items[objChanges.index[i]].template));
        },
    },
    mutations: {
        setActiveReports(state, activeReports) {
            state.activeReports = activeReports;
        },
        setEditingReports(state, editingReports){
            state.editingReports = editingReports;
        },
        setStatePage(state, objStatePage){
            for(let i in objStatePage){
                state.statePage[i] = objStatePage[i];
            }
        },
        setError(state, objError){
            for(let i in objError){
                state.error[i] = objError[i];
            }
        },
        setId(state, id){
            state.id = id;
        }
    },
    actions: {
        fetchReports({state, commit}){
        return $api.get(`${state.api}/GeneralReports/GetReportsObject`)
            .then((response)=>{
                console.info(
                    `[${Date.now()}] [FETCH-LOG] [vuex:reportsModule:fetchReports] [начало ->\n
                    ${JSON.stringify(response)}\n\n <-конец]`
                );
                if(response.status == 200){
                    commit('setId', response.data.id);
                    const reports = response.data?.tabs?.find(
                        (item)=>{
                            return item.name == 'GlobalReports';
                        }
                    )?.items.find(
                        (item)=>{
                            return item.name == 'ReportsCollection';
                        }
                    );
                    commit('setActiveReports', reports);
                }
                return response;
            })
            .catch((e)=>{
                console.error(
                    `[${Date.now()}] [Critical] [vuex:reportsModule:fetchReports] [начало ->\n
                    ${JSON.stringify(e)}\n\n <-конец]`
                )
                throw e;
            })
        },
        setError({commit}, e){
            for (let i in e) {
                commit('setError', {
                    [i]: e[i],
                });
            }
            commit('setError', {
                isError: true, 
                status: e.status ? e.status : -1,
            });
        },
        changeValueByArrayIndex({state, commit}, objChanges){
            let editingReports = state.editingReports;
            let temp = editingReports;
            let i = 0;
            for(; i < objChanges.index.length-1; i++){ 
                temp = temp.items[objChanges.index[i]]; 
            } 
            if(objChanges.index.length == 0){
                temp.items = objChanges.newValue;
            } else {
                temp.items[objChanges.index[i]].items = objChanges.newValue;
            }
            commit('setEditingReports', editingReports);
        },
        setEditingReports({commit}, objEditingReports){
            commit('setEditingReports', JSON.parse(
                JSON.stringify(objEditingReports)
            ))
        },
        setActiveReports({commit}, objActiveReports){
            commit('setActiveReports', JSON.parse(
                JSON.stringify(objActiveReports)
            ))
        },
        setStatePage({commit}, objStatePage){
            commit('setStatePage', objStatePage);
        },
        addItemToCollection({state, commit}, objChanges){
            console.log(`LOG: reportsModule: addItemToCollection:\n
                ${JSON.stringify(objChanges)}`
            );
            let editingReports = state.editingReports;
            let temp = editingReports;
            let i = 0;
            for(; i < objChanges.index.length-1; i++){ 
                temp = temp.items[objChanges.index[i]]; 
            } 
            if(objChanges.index.length == 0){
                temp.items.push(...objChanges.newValue);
            } else {
                temp.items[objChanges.index[i]].items.push(...objChanges.newValue);
            }
            
            commit('setEditingReports', editingReports);
        },
        deleteItemFromCollection({state, commit}, objChanges){
            console.log(`LOG: reportsModule: deleteItemFromCollection:\n
                ${JSON.stringify(objChanges)}`
            );
            let editingReports = state.editingReports;
            let temp = editingReports;
            let i = 0;
            for(; i < objChanges.index.length-1; i++){ 
                temp = temp.items[objChanges.index[i]]; 
            } 
            if(objChanges.index.length == 0){
                temp.items.splice(objChanges.chunkToDelete*objChanges.countattributes, objChanges.countattributes);
            } else {
                temp.items[objChanges.index[i]].items.splice(objChanges.chunkToDelete*objChanges.countattributes, objChanges.countattributes);
            }
            commit('setEditingReports', editingReports);
        },
    },
    namespaced: true
}