<template>
    <div class="d-icon">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DocIconPanel"   
}
</script>

<style scoped>
.d-icon{
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.d-icon:hover{
    background: rgba(255, 255, 255, 0.44);
}
</style>