<template>
    <div class="input-text-input">
        <div class="input-text-input__label block-label">
            <name-input 
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="changeObj.label">
            </name-input>
        </div>
        <div class="input-text-input__field block-field">
            <simple-input :obj="changeObj"
                @saveNewValue="handleSavingChanges"
                @eventHandler="logEvents">
            </simple-input>
        </div>
    </div>
</template>

<script>
import SimpleInput from '@/components/UI/typesInputs/SimpleInput.vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';
import { mapActions } from 'vuex';
import inputsHandler from '@/myLib/inputsHandler.js';
import { ref } from 'vue';
export default {
    name: "TextInput",
    components: {
        SimpleInput,
        NameInput
    },
    props: {
        obj: {
            label: String,
            items: String,
        },
        index: Array
    },
    setup(props){
        const changeObj = ref(props.obj);
        return {
            changeObj
        }
    },
    methods: {
        ...mapActions({
            setError: 'error/setError',
            setMessageError: 'error/setMessageError',
            setStatusMenuState: 'systemInfo/setStatusMenuState',
        }),
        handleSavingChanges(newValue){
            const value = newValue;
            const id = this.$route.params.id;
            const path = this.obj.path;
            const objToSave = {
                value: value,
                systemGuid: id,
                path: path,
            }
            inputsHandler.fetchSaveChanges(objToSave)
            .catch((error)=>{
                console.error(
                `[${Date.now()}] [Critical] [TextInput:handleSavingChanges] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            })
            .then(()=>{
                this.changeObj.items = newValue;
                this.setStatusMenuState(true);
            });
        },
        logEvents(obj){
            console.log(obj);
        },
    }
}
</script>

<style scoped>
.input-text-input{
    width: 100%;
    height: max-content;
    display: flex;
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}
.block-field{
    flex: 1;
    min-width: 20px;
}
.block-label{
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
</style>