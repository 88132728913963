<template>
    <div class="spinner-container">
        <div class="spinner rotating"></div>
    </div>
</template>

<script>
export default {
    name: "SpinLoader",
    props: {
        colorSpinner: {
            type: String,
            default: 'var(--color-base-100)'
        },
        sizeSpinner: {
            type: String,
            default: '24px'
        }
    },
    mounted() {
        this.$el.style.setProperty('--color-spinner', this.colorSpinner);
    }
}
</script>

<style scoped>
.spinner{
    border: 1px solid var(--color-spinner);
    border-top-color: transparent;
    border-radius: 50%;
    height: v-bind(sizeSpinner);
    aspect-ratio: 1;
}
@keyframes loading-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1800deg);
    }
}
.rotating{
    animation: loading-rotate 5s linear infinite;
}
</style>