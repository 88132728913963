<template>
    <progress class="horizontal-progress-bar" max="100" :value=percent>
    </progress>
</template>

<script>
export default {
    name: "HorizontalProgressBar",
    props: {
        percent: Number,
    }
}
</script>

<style scoped>
:root {
    --color-progress-value: rgba(27, 77, 255, 1);
}

.horizontal-progress-bar {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: transparent;
    border: 0.1px solid var(--color-state-grey-200);
}

.horizontal-progress-bar::-webkit-progress-bar {
    border: none;
    border-radius: 2px;
    background-color: transparent;
}

.horizontal-progress-bar::-webkit-progress-value {
    background-color: rgba(27, 77, 255, 1);
    border-radius: 2px;
}
.horizontal-progress-bar::-moz-progress-bar {
    border: none;
    border-radius: 2px;
    background-color: rgba(27, 77, 255, 1);
}
</style>

