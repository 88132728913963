<template>
    <div class="menu">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "MenuList",
    props: {
        menu: Array
    }
}
</script>

<style scoped>
.menu{
    display: flex;
    flex-direction: column;
}
</style>