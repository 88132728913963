const throttle = (callee, timeout) => {
    let timer = null;
    
    return function perform(...args) {
        if (timer) return

        timer = setTimeout(() => {
            callee(...args)

            clearTimeout(timer)
            timer = null
        }, timeout)
    }
}
const debounce = (callee, timeout) => {
    let timer = null;
    
    return function perform(...args) {
        if (timer) clearTimeout(timer);
        
        timer = setTimeout(() => {
            callee(...args);
        }, timeout);
    }
  }
  
export { throttle, debounce }