const protocol = window.location.protocol;
const hostName = window.location.hostname;
let config = {
  API_GATEWAY: protocol+'//'+hostName+'/api',
  API_GATEWAY_FILES: protocol+'//'+hostName+'/api',
  API_GATEWAY_CREATE_SYSTEMS: protocol+'//'+hostName+'/api',
  API_GATEWAY_REPORTS: protocol+'//'+hostName+'/api',
};

// async function loadConfig() {
//   try {
//     const data = await import('/public/api.json');
//     for (let key in data) {
//       config[key] += data[key];
//     }
//     console.log('Config loaded:', config);
//   } catch (error) {
//     console.error('Failed to load config:', error);
//   }
// }

// await loadConfig();

export default config;