import axios from "axios";
import apiConfig from "@/config/api.config.js";
const $api = axios.create();
const API_URL = `${apiConfig.API_GATEWAY}`;

$api.interceptors.request.use((config) => {
	config.headers.accessToken = `${localStorage.getItem("accessToken")}`;
	return config;
});

$api.interceptors.response.use((config) => {
    return config;
},(error) => {
    const originalRequest = error.config;
    console.log(error);
    if (error.response?.status == 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        console.log('auth: refreshing')
        return axios.post(`${API_URL}/Auth/RefreshToken`, {}, {
            headers:{
                refreshToken: `${localStorage.getItem('refreshToken')}`
            }
        })
        .then((response)=>{
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('profileGuid', response.data.profileGuid);
            return $api.request(originalRequest);
        })
        .catch(e=>{console.log('ошибка при refresh token');throw e;})
    } else {
        console.log('ошибка при попытке авторизации');
        throw error;
    }
})

export default $api;
