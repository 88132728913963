const fileHandler = {
    async getTextFromFilePath(filePath){
        return await 
        fetch(filePath)
            .then(res => res.text());
    },
    async getFormDataFromFile(file){
        const formData = new FormData();
        await formData.append('file', file);
        return await formData;
    }
}
export default fileHandler;