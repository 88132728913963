<template>
    <div class="d-flex align-items-center flex-column justify-content-center">
        <div v-if="this.title" class="title">
            {{ title }}
        </div>
        <div class="spinner-border" role="status"
            :class="this.class + ' spinner-border'">
            <span class="visually-hidden">Загрузка...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "GetLoading",
    props:{
        title: String,
        class: String
    }
}
</script>

<style scoped>
.page-systems-cards-loading{
    width: 159px; height: 159px; 
    color: #8674EA; border-width: 14px;
}
</style>