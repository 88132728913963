<template>
	<div class="phelp">
		<header class="phelp__title-page font-paragraph-3-medium">Помощь</header>
		<main class="phelp__content" v-if="!isLoading">
			<div class="phelp__content__container" v-if="!isSending">
				<div class="phelp__content__container__help-icon">
					<div class="svg-wrap">
						<svg
							width="337"
							height="276"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M252.408 258.637c-10.22-8.327 3.627-9.304 11.828-8.752v5.441l19.635 4.968c-6.23 2.917-21.244 6.671-31.463-1.657ZM306.108 253.67c-6.623.757-8.595-2.208-8.752-3.785h14.193c.947.946 1.183 3.028-5.441 3.785Z"
								fill="#1C222B"
							/>
							<path
								d="M291.205 230.723c-7.334 0-11.355-.473-17.269-.946M296.882 233.562v22.71M279.85 234.745l-15.614-1.183M287.183 237.11v22.947M286.947 260.53l9.935-4.021M273.699 229.777l-9.463 3.785M264 233.798v21.764M263.763 255.326l23.184 5.204M291.205 231.196v-6.624M311.786 249.648v-22.947M322.905 219.131v25.549M311.786 250.121l11.119-5.441M322.905 218.895l-25.076-.947M291.205 224.572l6.624-6.624M311.786 226.465l10.882-7.097M311.786 249.648h-13.957M291.441 224.572l13.485.473"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M102.409 268.595c.237-6.623 26.732-4.494 28.388-4.258v4.258l8.044 5.678c-12.223.315-36.621-.379-36.432-5.678Z"
								fill="#1C222B"
							/>
							<path
								d="M168.175 267.885v-24.129M147.593 274.036v-26.495M130.561 267.885V241.39M130.561 241.39l18.688-4.731M168.175 243.519l-20.582 3.785M147.593 274.273l20.582-6.151M168.175 243.519l-18.926-6.86M130.561 241.627l17.269 5.677"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M191.237 267.412c-4.352-7.57 9.542-8.674 17.033-8.28v5.442l24.366 2.838c-11.986 3.154-37.046 7.57-41.399 0Z"
								fill="#1C222B"
							/>
							<path
								d="m244.701 257.24-9.226 9.463M233.346 237.842l-23.894-1.656M219.152 230.035l25.549 1.183M208.979 236.186l-1.182 27.441M244.701 231.691v25.076M235.475 246.595v20.108M208.979 236.186l10.173-6.151M235.475 266.703l-27.915-2.839M191.839 58.769c1.025-.298 3.737-.58 6.387.67"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M182.376 70.384c1.829 0 3.312-1.5 3.312-3.35s-1.483-3.35-3.312-3.35c-1.829 0-3.312 1.5-3.312 3.35s1.483 3.35 3.312 3.35ZM209.345 74.852c1.829 0 3.312-1.5 3.312-3.35 0-1.851-1.483-3.352-3.312-3.352-1.83 0-3.312 1.5-3.312 3.351 0 1.85 1.482 3.35 3.312 3.35Z"
								fill="#1C222B"
							/>
							<path
								d="M174.333 61.673c.236-1.415 1.703-4.11 5.677-3.574M204.613 61.226c1.893-1.563 6.624-3.752 10.409 0M194.441 65.023c2.208 3.425 5.299 10.767 0 12.732l-2.602.894M185.451 85.573c.553 1.415 2.65 3.753 6.624 1.787"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M109.277 52.738c-7.759 24.124 13.799 41.621 25.549 47.354 10.598-6.075 25.707-9.158 32.173-10.051-8.043-6.255-10.488-17.721-11.118-23.007-4.259 1.34-15.614-3.798-10.409-14.296 4.163-8.399 8.674-4.095 10.409-.893l11.591 4.914c0-10.186 7.412-13.626 11.119-14.072 2.271-1.43 4.731-6.106 5.677-8.265 1.136 8.399 12.775 14.519 18.453 16.53 7.38-5.54 13.326-2.31 15.376 0l5.205-.671c10.172-3.276 28.435-14.474 20.108-33.059-8.327-18.584-25.549-15.189-33.119-11.168-25.928-16.261-51.966 7.52-61.744 21.443-9.857-1.638-31.511 1.117-39.27 25.241Z"
								fill="#1C222B"
							/>
							<path
								d="m167.236 56.982-11.355-5.584"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M191.602 56.535c0 7.704-5.576 13.626-12.065 13.626-6.488 0-12.064-5.922-12.064-13.626s5.576-13.625 12.064-13.625c6.489 0 12.065 5.92 12.065 13.625ZM222.829 61.45c0 7.703-5.577 13.625-12.065 13.625-6.489 0-12.065-5.922-12.065-13.626s5.576-13.625 12.065-13.625c6.488 0 12.065 5.921 12.065 13.626Z"
								stroke="#1C222B"
								stroke-width="1.596"
							/>
							<path
								d="M223.302 104.508c18.168-13.76 7.807-32.314.237-39.983-4.164 18.585-13.8 26.581-17.979 28.368l6.623 3.35 11.119 8.265Z"
								fill="#1C222B"
							/>
							<path
								d="M157.3 56.535c-.394-4.095-5.441-13.848-10.882-4.69-2.365 4.169-4.069 13.089 8.043 15.412"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M153.279 54.972c-1.104.893-2.745 3.216-.474 5.36M221.883 66.587c-1.325 7.148-4.259 13.622-7.334 17.646-1.923 2.516-5.038 5.692-9.011 8.46M155.644 67.257c-.157 9.084 3.597 22.56 20.819 29.708 2.649 1.1 5.253 1.73 7.784 1.984"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M203.904 107.91c-3.785-.893-7.412 2.01-8.753 3.574-.71 1.415-1.041 4.199 3.312 4.021 5.441-.224 7.097-7.595 13.484 4.244 5.11 9.471 5.441 28.07 4.968 36.185"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M191.52 105.453c-1.234 2.532-2.236 7.282 3.631 6.031"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M193.803 96.742c-3.312 1.34-8.115 6.165-6.222 9.381.236.372.946 1.072 1.892.894.415-.078 1.09-.76 2.047-1.564 1.776-1.492 4.52-3.406 8.362-2.68"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M260.003 173.442c2.366-10.945.061-36.628-28.894-61.288l-19.162-15.859-12.065-6.031-2.365-.447-8.99 2.01-.946.894-3.076 5.361c-.473 1.117 1.562 2.412 4.969 1.34M166.29 90.934c-15.377 1.936-55.295 10.259-79.486 44.004-22.8 31.803 2.986 45.205 22.148 45.875M1.2 185.281l106.692-4.914"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="m136.989 179.25 198.786-8.178M119.268 181.156c.293.857.077 1.472-1.062 1.565-3.372.273-7.377-4.663-8.957-7.165M119.26 171.463c1.683 2.538 4.618 6.45 7.191 8.67-.235 1.706-2 4.298-7.183 1.023-.789-2.311-5.277-6.374-5.277-6.623"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M123.826 169.075c3.22 3.354 9.062 10.541 6.674 12.452-.423.338-.963.371-1.581.173-.735-.237-1.58-.801-2.469-1.567-2.572-2.22-5.507-6.132-7.19-8.67"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
							<path
								d="M97.36 177.817c5.386-6.368 22.602-20.171 32.437-11.813 3.923 3.696 8.811 10.376 6.001 13.105-1.405 1.024-4.447-.341-6.408-3.07M116.547 141.5c-3.391 2.532-8.706 9.114-2.839 15.189"
								stroke="#1C222B"
								stroke-width="1.596"
								stroke-linecap="round"
							/>
						</svg>
					</div>
				</div>
				<div class="phelp__content__container__panel">
					<div class="phelp__content__container__panel__header">
						<div
							class="phelp__content__container__panel__header__title font-headline-6-semibold"
						>
							Сообщить о проблеме
						</div>
						<div
							class="phelp__content__container__panel__header__description font-paragraph-4-medium"
						>
							Пожалуйста, опишите проблему, с которой вы столкнулись. Укажите
							как можно больше деталей.
						</div>
					</div>
					<div class="phelp__content__container__panel__message">
						<div class="phelp__content__container__panel__message__field-area">
							<text-area
								:style="{height: '100%'}"
								@input="handleUpdateMessage"
								:placeholder="'- Что именно произошло и какие шаги привели к проблеме?\n- Ожидаемое поведение и фактический результат\n- Версия используемого браузера и операционной системы\n- Любая другая релевантная информация'"
							>
							</text-area>
						</div>
						<div class="phelp__content__container__panel__message__contacts">
							<text-field
								@input="handleUpdateContact"
								:label="'Контакт для связи с вами'"
								:placeholder="'Номер телефона, ник в Telegram или e-mail'"
							>
							</text-field>
						</div>
					</div>
					<div class="phelp__content__container__panel__send">
						<div class="phelp__content__container__panel__send__wrap-but">
							<ipt-button
								@click="handleSendMessage"
								:styles="{
									type: 'Primary',
									size: 'sm',
								}"
							>
								Отправить сообщение
							</ipt-button>
						</div>
					</div>
				</div>
			</div>
			<div class="phelp__content__container_after-sending" v-else>
				<div class="phelp__content__container_after-sending__thanks">
					<div
						class="phelp__content__container_after-sending__thanks__title font-headline-6-semibold"
					>
						Спасибо за ваш отзыв!
					</div>
					<div
						class="phelp__content__container_after-sending__thanks__message font-paragraph-4-medium"
					>
						Наша команда рассмотрит ваше сообщение <br />и ответит как можно
						скорее.
					</div>
				</div>
				<div class="phelp__content__container_after-sending__go-main">
					<div
						class="phelp__content__container_after-sending__go-main__wrap-button"
					>
						<ipt-button
							@click="handleClickGoSystem"
							:styles="{
								type: 'Secondary',
								size: 'sm',
							}"
						>
							Вернуться в главное меню
						</ipt-button>
					</div>
				</div>
			</div>
		</main>
		<div class="pz-loader phelp__content" v-else>
			<div class="pz-loader__container">
				<div class="pz-loader__container__left pz-loader__container__puzzle"></div>
				<div class="pz-loader__container__right pz-loader__container__puzzle"></div>
			</div>
		</div>
	</div>
</template>

<script>
import TextField from "@/components/UI/input/TextField.vue";
import TextArea from "@/components/UI/input/TextArea.vue";
import IptButton from "@/components/UI/buttons/IptButton.vue";
import apiConfig from "@/config/api.config.js";
import $api from "@/http/index.js";
import { ref } from "vue";
import { mapActions } from "vuex";
export default {
	name: "PageHelp",
	components: {
		TextField,
		TextArea,
		IptButton,
	},
	setup() {
		let message = ref("");
		let contacts = ref("");
		let isSending = ref(false);
		let messageInfo = ref("");
		let isLoading = ref(false);
		return {
			message,
			contacts,
			isSending,
			messageInfo,
			isLoading
		};
	},
	methods: {
		...mapActions({
			setMessageError: "error/setMessageError",
		}),
		handleClickGoSystem() {
			this.isSending = false;
			this.$router.push("/System");
		},
		handleUpdateContact(value) {
			this.contacts = value;
		},
		handleUpdateMessage(value) {
			this.message = value;
		},
		handleSendMessage() {
			const objToSend = {
				contact: this.contacts,
				message: this.message,
			};
			this.isLoading = true;
			this.fetchSendMessageToServer(objToSend)
				.then(() => {
					this.showMessage("Сообщение успешно отправлено");
					this.contacts = "";
					this.message = "";
					this.isSending = true;
				})
				.catch((error) => {
					this.showMessage(`При отправке сообщения возникла ошибка\n
                ${JSON.stringify(error)}`);
            })
			.finally(()=>{
				this.isLoading = false;
			})
        },
        showMessage(message){
            this.messageInfo = message;
            this.isOpenInfoModalToSaveChanges = true;
        },
        fetchSendMessageToServer(valueToSend){
            return $api.post(
                `${apiConfig.API_GATEWAY}/BugReport/PostHelpMessage`,
                valueToSend
            )
            .then((response)=>{return response;})
            .catch(error=>{throw error;});
        }
    },
}
</script>

<style scoped>
@keyframes up-down {
	0% {
		transform: translateY(10px);
	}
	50% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(10px);
	}
}
@keyframes down-up {
	0% {
		transform: translateY(-10px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(-10px);
	}
}
.pz-loader__container{
	display: flex;
	justify-content: center;
	align-items: center;
}
.pz-loader__container__left{
	background: url(@/assets/loaders/pz-left.svg);
	background-size: 'contain';
	animation: up-down 2s infinite;
}
.pz-loader__container__right{
	background: url(@/assets/loaders/pz-right.svg);
	background-size: 'contain';
	animation: down-up 2s infinite;
}
.pz-loader__container__puzzle{
	height: 36px;
	width: 28px;
}
.phelp {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
}
.phelp__title-page {
	width: 100%;
	padding: 16px 20px;
	display: flex;
	align-items: center;
	background-color: var(--color-base-00);
	border-bottom: 1px solid var(--color-state-grey-200);
}
.phelp__content {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.phelp__content__container {
	position: relative;
	display: flex;
	align-items: center;
	gap: 28px;
}
.phelp__content__container__help-icon {
	height: 457px;
	width: 374px;
	position: relative;
}
.svg-wrap {
	position: absolute;
	top: 56px;
	left: 0px;
}
.phelp__content__container__panel {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 502px;
}
.phelp__content__container__panel__header {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.phelp__content__container__panel__header__title,
.phelp__content__container__panel__header__description {
	display: flex;
	align-items: center;
}
.phelp__content__container__panel__message {
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow: hidden;
}
.phelp__content__container__panel__message__field-area {
	position: relative;
	height: 128px;
	max-height: 128px;
	overflow: hidden;
}
.phelp__content__container__panel__send {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.phelp__content__container_after-sending {
	width: 534px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.phelp__content__container_after-sending__thanks {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.phelp__content__container_after-sending__thanks__title,
.phelp__content__container_after-sending__thanks__message {
	text-align: center;
}
.phelp__content__container_after-sending__go-main {
	display: flex;
	justify-content: center;
}
.phelp__content__container_after-sending__go-main__wrap-button {
	width: 212px;
	white-space: nowrap;
}
</style>