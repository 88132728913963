import $api from "@/http/index.js";
import apiConfig from '@/config/api.config.js';

export const systemsCardsModule = {
    state: () => ({
        api: apiConfig.API_GATEWAY,
        apiCreateSystems: apiConfig.API_GATEWAY_CREATE_SYSTEMS,
        systems: [ ],
        activePropertyInCard: { },
        typeSystem: '',
        stateSystemPage:{
            isLoading: false,//загрузка не идет
            isCreatingSystemSuccess: true,
            isFiltersVisible: false,
            typeSystems: ''
        },
        error: {
            isError: false,
            status: 0,
            value: '',
        },
        activeFilters: [
            {
                name: "Тип системы",
                technicalName: "typeSystem",
                typeFilter: "enum",
                values: [
                    {
                        name: "CRM Система",
                        technicalName: "CRM"
                    },{
                        name: "ERP Система",
                        technicalName: "ERP"
                    }
                ]
            },
            {
                name: "Тип разработки",
                technicalName: "typeDevelopment",
                typeFilter: "enum",
                values: [
                    {
                        name: "Венчурная",
                        technicalName: "vench"
                    },{
                        name: "Вендорская",
                        technicalName: "vendor"
                    }
                ]
            }
        ],
        choosingFilters: [ ],
    }),
    getters: {
        getFilteringSystemsFromFilters(state){
            const filterSearching = state.choosingFilters?.find(
                (item)=>item?.objFilter?.technicalName === 'searching-filter-systems-cards'
            )?.value;
            return state.systems?.filter(
                (item) => {
                    if(item.status && state.stateSystemPage.typeSystems === 'archive'){
                        return item.status.id === '54061be1-fe9d-48f9-9d09-af4cb5c0d7f5';
                    } else {
                        return item.status.id !== '54061be1-fe9d-48f9-9d09-af4cb5c0d7f5';
                    }
                }
            )?.filter(
                (item) => filterSearching ? item.FirstValue.toLowerCase().includes(filterSearching.toLowerCase()) : true
            );
        },
        getFilteredSystems: (state) => (id) =>  {
            return state.systems.find((item)=>item.id === id);
        },
        getPropertiesSystem: (state) => (id) =>  {
            return state.systems.find((item)=>item.id === id)?.properties;
        },
        getActivePropertyInCard: (state) => (id) =>  {
            return state.activePropertyInCard[id];
        },
        getError(state){
            return state.error;
        },
        getActiveFilters(state){
            return state.activeFilters;
        },
        getChoosingFilters(state){
            const modelChoosingFilters = JSON.parse(JSON.stringify(state.choosingFilters));
            const res = [];
            for (let i = 0; i < modelChoosingFilters.length; ++i){
                res.push(
                    {
                        name: modelChoosingFilters[i].objFilter.name,
                        technicalName: modelChoosingFilters[i].objFilter.technicalName,
                        value: modelChoosingFilters[i].objFilter.value,
                        nameValue: modelChoosingFilters[i]?.objFilter?.values?.find((item)=> item.technicalName === modelChoosingFilters[i].objFilter.value)?.name
                    }
                )
            }
            return res;
        },
        getChoosingFiltersWithoutSearching(state){
            const modelChoosingFilters = JSON.parse(JSON.stringify(state.choosingFilters));
            const res = [];
            for (let i = 0; i < modelChoosingFilters.length; ++i){
                if(modelChoosingFilters[i].objFilter.technicalName === 'searching-filter-systems-cards') continue;
                res.push(
                    {
                        name: modelChoosingFilters[i].objFilter.name,
                        technicalName: modelChoosingFilters[i].objFilter.technicalName,
                        value: modelChoosingFilters[i].objFilter.value,
                        nameValue: modelChoosingFilters[i]?.objFilter?.values?.find((item)=>modelChoosingFilters[i].objFilter.value && item.technicalName === modelChoosingFilters[i].objFilter.value)?.name
                    }
                )
            }
            return res;
        },
        getTabFromListIdAndActiveAttributeId: 
        (state, getters) => 
            (listId, attributeId) => {
                return getters.getPropertiesSystem(listId) ? 
                    getters.getPropertiesSystem(listId)[
                        attributeId
                    ] : null;
        },
        getCountFillingAttributesFromTab: () => (objTab)=>{
            return objTab?.items?.reduce(
                (count, attribute) => {
                    return attribute.status === true ? count + 1 : count;
                },
            0);
        },
    },
    mutations: {
        setTypeSystem(state, typeSystem) {
            state.typeSystem = typeSystem;
        },
        setSystems(state, systems) {
            state.systems = systems;
        },
        setStateSystemPage(state, objState) {
            for (let i in objState) {
                state.stateSystemPage[i] = objState[i];
            }
        },
        setActivePropertyInCard(state, objActivePropertyInCard) {
            state.activePropertyInCard[objActivePropertyInCard.id] = objActivePropertyInCard.value;
        },
        setError(state, errorObj) {
            for(let i in errorObj) {
                state.error[i] = errorObj[i];
            }
        },
        setChoosingFilter(state, choosingFilter) {
            const index = state.choosingFilters.findIndex(item => item?.objFilter?.technicalName && item.objFilter.technicalName === choosingFilter.objFilter.technicalName);
            if (index !== -1) {
                state.choosingFilters.splice(index, 1, choosingFilter);// Если фильтр с таким техническим именем уже существует, заменяем его
            } else {
                state.choosingFilters.push(choosingFilter);// Если фильтра с таким техническим именем нет, добавляем новый
            }
        },
        removeAllFilters(state) {
            state.choosingFilters = [];
        },
        removeChoosingFilter(state, removingFilter){
            const index = state.choosingFilters.findIndex(item => item.objFilter.technicalName === removingFilter.technicalName);
            if (index !== -1) {
                state.choosingFilters.splice(index, 1);
            } else {
                return;
            }
        },
    },
    actions: {
        // fetchObjects({state, commit, getters}, objFetch){
        //     const Size = objFetch.size;
        //     let dateTimeProperty = '';
        //     if(objFetch.isDownAppending){
        //         dateTimeProperty = 'MinDateTime?=' + state.systems[state.systems.length - 1].createdAt;
        //     } 
        //     if(objFetch.isUpAppending){
        //         dateTimeProperty = 'MaxDateTime?=' + state.systems[0].createdAt;
        //     }
        //     return $api.get(`${state.api}/api/Object/GetObjects?type=${state.typeSystem}&Size=${Size}&${(objFetch.isDownAppending || objFetch.isUpAppending) ? dateTimeProperty : ''}`)
        //     .then

        // },
        fetchUpAppendingSystems({state, commit}, objFetch){
            if(state.systems?.length===0) return [];
            const type = objFetch.type;
            const Size = objFetch.Size;
            const dateParam = 'ExtremumDateTime=' + state.systems[0].createdAt;
            const down = `Down=` + false;
            const search = objFetch.search?`&search=${objFetch.search}`:'';
            return $api.get(`${state.api}/Object/GetObjects?type=${type}&Size=${Size}&${dateParam}&${down}${search}`)
            .then((response)=>{
                if(!response.data || response.data.length===0) return [];
                const length = response.data.length;
                console.log('UP' ,response.data);
                commit('setSystems', [...JSON.parse(JSON.stringify(response.data)), ...state.systems.slice(0, -length)]);
                return response.data;
            })
        },
        fetchDownAppendingSystems({state, commit}, objFetch){
            if(state.systems?.length===0) return [];
            const type = objFetch.type;
            const Size = objFetch.Size;
            const search = objFetch.search?`&search=${objFetch.search}`:'';
            if(state.systems.length <= 0) return;
            const dateParam = 'ExtremumDateTime=' + state.systems[state.systems.length - 1].createdAt;
            const down = `Down=` + true;
            return $api.get(`${state.api}/Object/GetObjects?type=${type}&Size=${Size}&${dateParam}&${down}${search}`)
            .then((response)=>{
                if(!response.data || response.data.length===0) return [];
                const length = response.data.length;
                console.log('DOWN' , response.data.length, response.data);
                commit('setSystems', [...state.systems.slice(length), ...JSON.parse(JSON.stringify(response.data))]);
                return response.data;
            })
        },
        fetchFilteredSystems({ state, commit }, objFetch) {
            const type = objFetch.type;
            const Size = objFetch.Size;
            const search = objFetch.search?`&search=${objFetch.search}`:'';
            if(objFetch.search===undefined){
                commit('setStateSystemPage', {isLoading: true});
            } else {
                commit('setStateSystemPage', {isFiltersLoading: true});
            }
            //идет загрузка
            commit('setTypeSystem', type);
            return $api.get(`${state.api}/Object/GetObjects?type=${state.typeSystem}&Size=${Size}${search}`)
                .then(response =>{
                    commit('setSystems', response.data ? response.data : []);
                    console.info(
                        `[${Date.now()}] [FETCH-LOG] [vuex:systemsCards:fetchFilteredObjects] [системы начало->\n
                        ${JSON.stringify(response)}\n\n<-конец]`
                    );
                }).catch(e=>{
                    console.error(
                        `[${Date.now()}] [Critical] [vuex:systemsCards:fetchFilteredSystems] [начало->\n
                        ${JSON.stringify([e, e.response])}\n\n<-конец]`
                    )
                    throw e;
                }).finally(()=>{
                    commit('setStateSystemPage', {isLoading: false});
                    commit('setStateSystemPage', {isFiltersLoading: false});
                });
        },
        setSystems({commit}, systems){
            commit('setSystems', systems);
        },
        setActivePropertyInCard({commit}, objActivePropertyInCard){
            commit('setActivePropertyInCard', objActivePropertyInCard);
        },
        fetchCreateSystem({state, commit}, newSystem){
            const type = newSystem.typeSystem;
            delete newSystem.typeSystem;
            return $api.post(
                `${state.apiCreateSystems}/Object/CreateObject?typeObject=${type}`, 
                newSystem
            )   .then(response => {
                    console.info(
                        `[${Date.now()}] [FETCH-LOG] [vuex:systemsCards:fetchCreateSystem] [начало->\n
                        ${JSON.stringify(response?response:{})}\n\n<-конец]`
                    );
                }).catch(e => {
                    console.error(
                        `[${Date.now()}] [Major] [vuex:systemsCards:fetchCreateSystem] [начало->\n
                        ${JSON.stringify(e)}\n\n<-конец]`
                    );
                    commit('setStateSystemPage', {isCreatingSystemSuccess: false});
                    throw e;
                })
        },
        setError({commit}, e){
            for (let i in e) {
                commit('setError', {
                    [i]: e[i],
                });
            }
            commit('setError', {
                isError: true, 
                status: e.status ? e.status : -1,
            });
        },
        setStateSystemPage({commit}, obj){
            commit('setStateSystemPage', obj);
        },
        setChoosingFilter({commit}, filter){
            commit('setChoosingFilter', filter);
        },
        removeChoosingFilter({commit}, filter){
            commit('removeChoosingFilter', filter);
        },
        removeAllFilters({commit}){
            commit('removeAllFilters');
        }
    },
    namespaced: true
}