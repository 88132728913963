<template>
    <div class="template-file" :class="obj.isRequiredToFilling && !obj.items ? 'requiredToFilling' : 'not-required'">
        <div class="add-file">
            <label class="add-file-upload" title="Загрузить файл">
                <ipt-button :styles="{
                    type: 'Link Grey',
                    size: 'xsm',
                    state: ''
                }">
                    <template v-slot:iconLeft>
                        <icon-button :styles="{
                            type: 'Link Grey',
                            size: 'xsm',
                            state: ''
                        }">
                            <template v-slot="{ width, height, fill }">
                                <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.875 8H11.125M8 4.875V11.125M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
                                        :stroke=fill stroke-linecap="round" />
                                </svg>
                            </template>
                        </icon-button>
                    </template>
                    Выбрать файл
                </ipt-button>
                <input class="input-file-but" type="file" name="file[]" @input="handleUploadingFile($event)"
                    :disabled="isReadOnly" @focus.prevent>
            </label>
            <div v-if="isPercentUploadingDisplaying" class="add-file__upload-percents font-paragraph-5-medium">
                {{ state.persentToUploading }}%
            </div>
        </div>
        <div v-if="isPercentUploadingDisplaying" class="progress-bar">
            <horizontal-progress-bar :percent="state.persentToUploading"></horizontal-progress-bar>
        </div>
        <div v-if="this.obj.items?.length > 0" class="files">
            <div v-for="item in this.obj.items" :key="item.name" class="file-entity"
                :title="'Файл: ' + item.UserFileName">
                <div class="file-entity__panel">
                    <div @click.stop="handleDownloadFile(item)" class="file-entity__panel__download-file">
                        <svg width="16" height="16" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Скачать файл: {{ item.UserFileName }}</title>
                            <path class="svg-download-but"
                                d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875M6.71875 6.40539L10 3.125L13.2812 6.40539M10 11.875V3.1273"
                                stroke="var(--color-state-grey-500)" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div class="file-entity__file-name font-paragraph-6-regular">
                    {{ item.UserFileName }}
                </div>
                <div class="file-entity__panel">
                    <div @click.stop="handleDeleteFile(item)" class="file-entity__panel__but-to-delete">
                        <svg width="16" height="16" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Удалить файл: {{ item.UserFileName }}</title>
                            <path class="svg-delete-but"
                                d="M11.2499 8.75V14.75M6.74988 8.75V14.75M17.2495 4.25L0.749512 4.25001M15.7499 4.25V18.5C15.7499 18.6989 15.6709 18.8897 15.5302 19.0303C15.3896 19.171 15.1988 19.25 14.9999 19.25H2.99988C2.80097 19.25 2.6102 19.171 2.46955 19.0303C2.3289 18.8897 2.24988 18.6989 2.24988 18.5V4.25M12.7499 4.25V2.75C12.7499 2.35218 12.5918 1.97064 12.3105 1.68934C12.0292 1.40804 11.6477 1.25 11.2499 1.25H6.74988C6.35205 1.25 5.97052 1.40804 5.68922 1.68934C5.40791 1.97064 5.24988 2.35218 5.24988 2.75V4.25"
                                stroke="var(--color-state-grey-500)" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <m-accept :isModalOpen="isApproveDeletingWindowOpen" 
        :nameAcceptableButton="'Удалить'"
        :nameCancelButton="'Отменить'" :modal="{
            message: this.messageToApproveDeleting,
        }" @exit="handleDeleteCancelFile">
    </m-accept>
</template>

<script>
import IptButton from '@/components/UI/buttons/IptButton.vue';
import IconButton from '@/components/UI/buttons/icon-buttons/IconButton.vue';
import HorizontalProgressBar from '@/components/UI/progress-bar/HorizontalProgressBar.vue';
import MAccept from '@/components/UI/modal-window/MAccept.vue';
import filesFromServe from '@/myLib/filesFromServe';
import apiConfig from '@/config/api.config.js';
import { ref } from 'vue';
export default {
    name: "SimpleFiles",
    components: {
        IptButton,
        IconButton,
        HorizontalProgressBar,
        MAccept
    },
    props: {
        obj: {
            label: String,
            items: String,
            path: String,
        },
        state: {
            disabled: Boolean,
            default: false,
            persentToUploading: Number
        }
    },
    emits: ['uploadFile', 'deleteFile', 'eventHandler'],
    setup() {
        const objFiles = {
            "isActive": true,
            "name": "TestPGFile",
            "label": "TestPGFile",
            "type": "File",
            "path": "TestPG.TestPGFile",
            "order": 0,
            items: [
                {
                    name: 'klefwljldkwe',
                    label: 'aga file'
                },
                {
                    name: 'dwqdwqkldkwq',
                    label: 'NE AGA TEBE File'
                },
                {
                    name: 'qkwjekjkqwje',
                    label: 'Первый файл'
                },
                {
                    name: 'qkwjekqwejkqwje',
                    label: 'Второй файл'
                },
                {
                    name: 'qkwjekqwe12123jkqwje',
                    label: 'Третий файл'
                },
                {
                    name: 'qkwjekjkqweqweqweqweqwje',
                    label: 'Четвертый файл'
                }
            ]
        };
        const apiFiles = ref(apiConfig.API_GATEWAY_FILES);
        let fileToDelete = ref(null);
        let isApproveDeletingWindowOpen = ref(false);
        let messageToApproveDeleting = ref('');
        return {
            objFiles, apiFiles, fileToDelete,
            messageToApproveDeleting,
            isApproveDeletingWindowOpen
        }
    },
    computed: {
        isPercentUploadingDisplaying() {
            return this.state.persentToUploading > 0
                && this.state.persentToUploading < 100;
        }
    },
    methods: {
        eventHandler(from, obj, message) {
            const description = `[${Date.now()}] [LOG] [SimpleInput:${from}] [объект ${obj ? JSON.stringify(obj) : 'не обрабатывался'} сообщение: ${message ? message : 'отсутствует'}]`;
            this.$emit('eventHandler', { description: description, value: obj });
        },
        handleUploadingFile(event) {
            if (event?.target?.files?.length === 0) return;
            const files = event.target.files;
            for (let i = 0; i < files.length; ++i) {
                this.$emit('uploadFile', files[i]);
                this.eventHandler('handleUploadingFile', files[i], 'Загружается файл');
            }
            if(event?.target) event.target.value = '';
        },
        handleDeleteFile(item) {
            this.messageToApproveDeleting = `Вы точно хотите удалить файл: ${item.UserFileName}?\nЭто действие нельзя будет отменить.`
            this.isApproveDeletingWindowOpen = true;
            this.fileToDelete = JSON.parse(JSON.stringify(item));
        },
        handleDeleteCancelFile(isDeleteAccepted){
            this.isApproveDeletingWindowOpen = false;
            try {
                if (isDeleteAccepted) {
                    this.deleteFile(this.fileToDelete);
                }
            } finally {
                this.fileToDelete = null;
            }
        },
        deleteFile(item){
            this.$emit('deleteFile', item);
            this.eventHandler('handleDeleteFile', item, `Удаляется файл по атрибутам из объекта ${item}`);
        },
        async handleDownloadFile(item) {
            const id = this.$route.params.id;
            const path = this.obj.path;
            const technicalName = item.TechnicalFileName;
            const userFileName = item.UserFileName;
            const url = `${this.apiFiles}/Object/GetFile/${id}/${path}?technicalFileName=${technicalName}&userFileName=${userFileName}`;
            try {
                await filesFromServe.downloadFileFromLink(url);
            } catch (e) {
                console.error(JSON.stringify(e));
            }
        }
    },
}
</script>

<style scoped>
.requiredToFilling {
    background-color: var(--color-error-red-25);
}

.not-required {
    /* background-color: var(--color-state-grey-25); */
}

.file-entity__panel__but-to-delete:hover .svg-delete-but {
    stroke: var(--color-error-red-600);
}

.file-entity__panel__download-file:hover .svg-download-but {
    stroke: var(--color-primary-blue-600);
}

.add-file-upload {
    width: fit-content;
    cursor: pointer;
}

.template-file {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 8px;
    gap: 10px;
}

.add-file {
    width: 100%;
    display: flex;
    align-items: center;
    height: 24px;
    gap: 10px;
}

.files {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    width: 100%;
}

.file-entity {
    width: 167px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border: 1px solid var(--color-state-grey-200);
    border-radius: 4px;
}

.file-entity__file-name {
    padding: 0px 2px 0px 3px;
    color: var(--color-state-grey-800);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-entity__panel {
    display: flex;
    gap: 8px;
}

.file-entity__panel__but-to-delete,
.file-entity__panel__download-file {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.input-file-but {
    z-index: -1;
    overflow: hidden;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    opacity: 0;
}
</style>