<script>
import { ref } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf';
import DocIconPanel from '@/components/docs-template/DocIconPanel.vue';
import CopyDocText from '@/components/docs-template/CopyDocText.vue';
export default {
    name: "CardDoc",
    components:{
        CopyDocText,
        VuePDF,
        DocIconPanel,
    },
    props:{
        obj:{
            urlFile: String,
            name: String,
            value: String,
            pagesWidth: {
                type: Array,
                default: () => []
            }
        }, 
    },
    setup(props) {
        let page = ref(1);
        const { pdf, pages } = usePDF(props.obj.urlFile);
        let isDocumentFullyOpen = ref(false);
        return {
            page, pdf, pages, isDocumentFullyOpen
        }
    },
    methods: {
        handleOpenDoc() {
            this.isDocumentFullyOpen = true;
        },
        handleCloseDoc() {
            this.isDocumentFullyOpen = false;
        }
    }
}

</script>

<template>
    <div class="cdocument_backdrop" v-if="isDocumentFullyOpen" @click="handleCloseDoc">
        <div @click.stop class="cdocument_fully-open" :style="{width: obj.pagesWidth[page-1]+'px'}">
            <div class="cdocument_fully-open__exit exit-fully-open manage">
                <doc-icon-panel @click="handleCloseDoc">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 1.68359L0.75 15.1836M14.25 15.1836L0.75 1.68359" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </doc-icon-panel>
            </div>
            <div class="cdocument_fully-open__left-page panel-button-go-page">
                <doc-icon-panel @click="page = page > 1 ? page - 1 : page">
                    <svg class="rotated" width="10" height="17" viewBox="0 0 10 17" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 0.933594L9 8.43359L1.5 15.9336" stroke="white" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </doc-icon-panel>
            </div>
            <div class="cdocument_fully-open__page scroll-bar-grey-fat">
                <VuePDF :pdf="pdf" :page="page" fit-parent :key="page"/>
            </div>
            <div class="cdocument_fully-open__right-page panel-button-go-page">
                <doc-icon-panel @click="page = page < pages ? page + 1 : page">
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 0.933594L9 8.43359L1.5 15.9336" stroke="white" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </doc-icon-panel>
            </div>
            <div class="cdocument_fully-open__mark-pages font-paragraph-5-regular">
                Страница {{ page }} из {{ pages }}
            </div>
        </div>
    </div>
    <div class="cdocument">
        <div class="cdocument__block-card">
            <div class="cdocument__block-card__card ipt-shadow-xl">
                <div @click="handleOpenDoc" class="cdocument__block-card__card__icon">
                    <doc-icon-panel @click="page = page > 1 ? page - 1 : page">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.875 10.8749H13.875M10.875 7.87488V13.8749M16.4431 16.4433L20.9995 20.9996M18.75 10.8745C18.75 15.2238 15.2242 18.7495 10.875 18.7495C6.52576 18.7495 3 15.2238 3 10.8745C3 6.52527 6.52576 2.99951 10.875 2.99951C15.2242 2.99951 18.75 6.52527 18.75 10.8745Z"
                            stroke="#5E718D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </doc-icon-panel>
                </div>
                <VuePDF :pdf="pdf" :page="1" :height="308"/>
            </div>
        </div>
        <div class="cdocument__panel">
            <div class="cdocument__panel__title-doc font-paragraph-4-medium">
                {{ obj.name }} 
            </div>
            <div class="cdocument__panel__copy-latex">
                <copy-doc-text
                    :items="obj.value"
                    :label="'Скопировать LaTeX-код'"
                    >
                </copy-doc-text>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pdf-doc-overview{
    border: 1px solid red;
}
.cdocument_backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 99;
}
.cdocument_fully-open {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 24px;
    max-height: 90vh;
    height: 90vh;
    width: 650px;
    overflow: hidden;
}
.cdocument_fully-open__page {
    position: relative;
    flex: 1 1 auto;
    min-height: 0;
    height: 100%;
    overflow: auto;
}
.cdocument_fully-open__exit{
    position: absolute;
    top: 0;
    right: 0;
}
.rotated {
    transform: rotate(-180deg);
}
.cdocument_fully-open__mark-pages{
    opacity: 0.5;
    border-radius: 63px;
    color: var(--color-state-grey-900);
    background-color: var(--color-state-grey-200);
    padding: 8px 16px;
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    z-index: 101;
}
.cdocument {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    max-width: 317px;
}

.cdocument__block-card {
    min-width: 317px;
    min-height: 352px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-state-grey-25);
}

.cdocument__block-card:hover {
    background: rgba(0, 0, 0, 0.1);
}

.cdocument__block-card__card {
    position: relative;
    overflow: hidden;
    min-width: 218px;
    min-height: 308px;
}

.cdocument__panel {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.cdocument__block-card__card__icon {
    display: none;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cdocument__block-card:hover .cdocument__block-card__card__icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cdocument__panel__title-doc{
    text-align: left;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>