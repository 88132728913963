import $api from "@/http/index.js";
import apiConfig from '@/config/api.config.js';

export const systemInfoModule = {
    state: () => ({
        activeSystem: {},
        editingSystem: {},
        id: String,
        activeSystemTab: '',//
        stateSystemPage: {
            isEmpty: false,
            isLoading: false,
            isError: false,
        },
        typeContent: {
            systemInfo: ['',],
            systemSandbox: ['sandbox',],
        },
        api: apiConfig.API_GATEWAY,
        error: {
            isError: false,
            status: 0,
        },
        statusMenu :{
            isLoadingChanges: false,
            items: [ ],
        }
    }),
    getters: {
        getStatusMenuIsLoading: (state) => {
            return state.statusMenu.isLoadingChanges;
        },
        getStatusMenuItems: (state) => {
            return state.statusMenu.items;
        },
        getSortingItems: (state, getters)=>(obj)=>{
            const map = {
                "PropertiesGroup": true,
                "Group": true,
                "InCollection": true,
                "Collection": true,
            }
            if(!map[obj.type]) return;
            if(obj.type=='Collection'){
                getters.getSortingItemsInCollection(obj);
                console.log('obj.items', obj.items);
            } else {
                for(let i = 0; i < obj.items.length; ++i){
                    getters.getSortingItems(obj.items[i]);
                }
                let sortedObj = obj.items.sort((a, b) => {
                    return a.order - b.order;
                });
                obj.items = JSON.parse(JSON.stringify(sortedObj));
            }
        },
        getSortingItemsInCollection: (state, getters) =>(collection)=>{
            console.log('getSortingItemsInCollection', collection);
            const tempObj = { type: "InCollection" }; const items = [];
            let itemArrayPerCycle = [];
            for(let i = 0; i < collection.items.length; ++i){
                itemArrayPerCycle = [];
                for(let j = 0; j < collection.items[i].length; ++j){
                    itemArrayPerCycle.push(collection.items[i][j]);
                }
                console.log('itemArrayPerCycle', itemArrayPerCycle);
                tempObj.items=JSON.parse(JSON.stringify(itemArrayPerCycle));
                getters.getSortingItems(tempObj);
                items.push([...tempObj.items]);
                // alert(JSON.stringify(items));
                console.log('items', items);
            }
            collection.items = JSON.parse(JSON.stringify(items));
            console.log('collection.items', collection.items);
        },
        getSortingSystemByOrders: (state, getters)=>(obj)=>{
            console.log(JSON.stringify(obj));
            obj.type="PropertiesGroup";
            obj.items=JSON.parse(JSON.stringify(obj.tabs));
            getters.getSortingItems(obj);
            obj.tabs=JSON.parse(JSON.stringify(obj.items));
            console.log('Log', obj.tabs);
            delete obj.items;
            return obj;
        },
        getActiveSystem(state) {
            return state.activeSystem;
        },
        getActiveSystemTab(state) {
            return state.activeSystemTab;
        },
        getMenuItems: (state) => (nameParamInRouter) => {
            return state.activeSystem?.tabs?.filter(
                (item) => item.isActive === true
            )
                .map(
                    (item) => {
                        let temp = {};
                        temp.label = item.label;
                        temp[nameParamInRouter] = item.name;
                        return { ...temp };
                    }
                ) 
            || [];
        },
        getActiveSystemTabContent(state) {
            return state.activeSystem?.tabs
                ?.find(
                    (item) => 
                        item.name == state.activeSystemTab
                        && ~item.items
                            .findIndex( 
                                it => it.isActive === true
                            )
                );
        },
        getEditingSystemTabContent(state) {
            return state.editingSystem?.tabs?.find((item) => item.name == state.activeSystemTab);
        },
        getTemplateOfElementCollection: (state) => (objChanges) => {
            let editingSystem = state.editingSystem;
            let editingSystemTabContent = editingSystem.tabs.find((item) => item.name == state.activeSystemTab);

            let temp = editingSystemTabContent;
            let i = 0;
            for (; i < objChanges.index.length - 1; i++) {
                temp = temp.items[objChanges.index[i]];
            }
            return JSON.parse(JSON.stringify(temp.items[objChanges.index[i]].template));
        },
        getActiveTypeContentFromActiveSystemTab(state) {
            for (let i in state.typeContent) {
                for (let j = 0; j < state.typeContent[i].length; ++j) {
                    if (state.typeContent[i][j] === state.activeSystemTab) {
                        return i;
                    }
                }
            }
        }
    },
    mutations: {
        setStatusMenuItems(state, items) {
            state.statusMenu.items = items;
        },
        setStatusMenuState(state, status) {
            state.statusMenu.isLoadingChanges = status;
        },
        setLoading(state, isLoading) {
            state.stateSystemPage.isLoading = isLoading;
        },
        setActiveSystem(state, activeSystem) {
            state.activeSystem = activeSystem;
        },
        setEditingSystem(state, editingSystem) {
            state.editingSystem = editingSystem;
        },
        setStateSystemPage(state, objStateSystemPage) {
            for (let i in objStateSystemPage) {
                state.stateSystemPage[i] = objStateSystemPage[i];
            }
        },
        setid(state, id) {
            state.id = id;
        },
        setActiveSystemTab(state, activeSystemTab) {
            state.activeSystemTab = activeSystemTab;
        },
        setError(state, errorObj) {
            for (let i in errorObj) {
                state.error[i] = errorObj[i];
            }
        },
    },
    actions: {
        setStatusMenuItems({state, commit, getters, dispatch}, objTab) {
            commit('setStatusMenuItems', []);
            const id = objTab.id;
            const tab = objTab.tab;
            const url = `${state.api}/Object/GetProperties/${id}` + (tab ? `?PropertyName=${tab}` : '');
            let items = getters.getStatusMenuItems;
            return $api.get(url)
            .then(response =>{
                const data = response.data;
                if(!data?.length) return;
                if (items.length === 0){
                    commit('setStatusMenuItems', data);
                    items = data;
                } else {
                    if(!items || !items.length || !data || !data?.length) return;
                    for(let i = 0; i < data.length; ++i) {
                        items.find((it)=>{
                            return it.name === data[i]?.name;
                        }).items = data[i]?.items || [];
                    }
                    commit('setStatusMenuItems', items);
                }
                return items;
            })
            .catch(e => {
                const error = e.response ? e.response : e;
                dispatch('setError', error);
                throw error;
            })
            .finally(()=>{
                commit('setStatusMenuState', false);
            });
        },
        setStatusMenuState({commit}, status) {
            commit('setStatusMenuState', status);
        },
        fetchSaveChanges({state, dispatch}, obj){
            console.log(obj, 'aha');
            const value = obj.value;
            const systemGuid = obj.systemGuid;
            const path = obj.path;
            const url = `${state.api}/Systems/PutValue/${systemGuid}/${path}`;
            return $api.put(
                url,{
                    data: value
                }     
            ).then(response => (response))
            .catch(e => {
                const error = e.response ? e.response : e;
                dispatch('setError', error);
                throw error;
            });
        },
        fetchPutSystemInfo({ state, getters, commit, dispatch }, id) {
            if (!id) {
                // alert("systemInfoModule->fetchPutSystemInfo: id is null");
                return;
            }
            const url = `${state.api}/Systems/PutSystem/${id}`;
            return $api.put(
                url, JSON.parse(
                    JSON.stringify(getters.getEditingSystemTabContent)
                )
            ).then((response) => {
                console.info(
                    `[${Date.now()}] [FETCH-LOG] [vuex:systemsInfo:fetchPutSystemInfo] [начало->\n
                    ${JSON.stringify(response)}\n\n<-конец]`
                );
                const obj = getters.getSortingSystemByOrders(response.data);
                commit('setActiveSystem', obj ? obj : {});
            }).catch((e) => {
                console.error(
                    `[${Date.now()}] [Critical] [vuex:systemsInfo:fetchPutSystemInfo] [начало->\n
                    ${JSON.stringify(e)}\n\n<-конец]`
                )
                const error = e.response ? e.response : e;
                dispatch('setError', error);
                throw e;
            });
        },
        fetchActiveSystem({ state, commit, dispatch, getters }, id) {//по идее можно и без аргументов
            if (!id) {
                // alert("systemInfoModule->fetchActiveSystem: id is null");
                return;
            }
            commit('setLoading', true);//идет загрузка
            const url = `${state.api}/Object/GetObject/${id}`;
            return $api.get(
                url
            ).then(response => {
                console.info(
                    `[${Date.now()}] [LOG] [vuex:fetchActiveSystem] [объект ответа начало ->\n
                    ${JSON.stringify(response.data)} \n\n <- конец url: ${url}]`
                );
                const obj = getters.getSortingSystemByOrders(response.data);
                commit('setActiveSystem', obj ? obj : {});
                return response.data;
            }).catch((e) => {
                const error = e.response ? e.response : e;
                dispatch('setError', error);
                commit('setActiveSystem', null);
                console.log(`LOG: systemInfoModule->fetchActiveSystem ${e}`);
                throw e;
            }).finally(() => {
                commit('setLoading', false);
            });
        },
        setid({ commit }, id) {
            commit('setid', id);
        },
        setStateSystemPage({ commit }, objStateSystemPage) {
            commit('setStateSystemPage', objStateSystemPage);
        },
        setActiveSystemTab({ commit }, activeSystemTab) {
            commit('setActiveSystemTab', activeSystemTab);
        },
        setEditingSystem({ commit }, editingSystem) {
            commit('setEditingSystem', editingSystem);
        },
        changeValueByArrayIndex({ state, commit }, objChanges) {
            let activeSystema = state.activeSystem;
            let editingSystemTabContent = activeSystema.tabs.find((item) => item.path == state.activeSystemTab);
            let temp = editingSystemTabContent;
            let i = 0;
            for (; i < objChanges.index.length - 1; i++) {
                temp = temp.items[objChanges.index[i]];
            }
            temp.items[objChanges.index[i]].items = objChanges.newValue;
            commit('setActiveSystem', activeSystema);
        },
        addItemToCollection({ state, commit }, objChanges) {
            let editingSystem = state.editingSystem;
            let editingSystemTabContent = editingSystem.tabs.find((item) => item.path == state.activeSystemTab);
            let temp = editingSystemTabContent;
            let i = 0;
            for (; i < objChanges.index.length - 1; i++) {
                temp = temp.items[objChanges.index[i]];
            }
            temp.items[objChanges.index[i]].items.push(...objChanges.newValue);
            commit('setEditingSystem', editingSystem);
        },
        deleteItemFromCollection({ state, commit }, objChanges) {
            let editingSystem = state.editingSystem;
            let editingSystemTabContent = editingSystem.tabs.find((item) => item.path == state.activeSystemTab);
            let temp = editingSystemTabContent;
            let i = 0;
            for (; i < objChanges.index.length - 1; i++) {
                temp = temp.items[objChanges.index[i]];
            }
            temp.items[objChanges.index[i]].items.splice(objChanges.chunkToDelete * objChanges.countattributes, objChanges.countattributes);
            commit('setEditingSystem', editingSystem);
        },
        setActiveSystem({ commit }, activeSystem) {
            commit('setActiveSystem', activeSystem);
        },
        async setError({ commit }, e) {
            for (let i in e) {
                commit('setError', {
                    [i]: e[i],
                });
            }
            commit('setError', {
                isError: true,
                status: e.status ? e.status : -1,
            });
        },
    },
    namespaced: true
}