import $api from "@/http/index.js"; 
const filesFromServe = {
    async downloadFileFromLink(url){
        try{
            let a = document.createElement("a");
            const response = await $api.get(url);
            const fileURL = response.data;
            a.href = fileURL;
            a.download = 'defailt-name'; // Используем имя файла, полученное из заголовков ответа
            document.body.appendChild(a); // Добавляем элемент в документ для корректной работы в Firefox
            a.click();
            document.body.removeChild(a); // Удаляем элемент после клика
            URL.revokeObjectURL(fileURL); // Освобождаем URL
        } catch(error){
            console.error(`ERROR_FETCH: downloadFileFromLink: ${error}`);
            throw error;
        }
    },
    async fetchGetFileFromLink(url){
        try {
            const response = await $api.get(url);
            return response.data
        } catch (error) {
            console.error(`ERROR_FETCH: fetchGetFileFromLink: ${error}`);
            throw error;
        }
    }
}
export default filesFromServe;