<template>
    <div class="search">
        <label class="search__panel"
            for="search-input-systems-cards">
            <input ref="input-filter"
                v-model="value"
                @keyup.enter="handleAddFilterValue"
                @keyup.esc.prevent="removeFilter"
                class="font-paragraph-4-medium search-input"
                type="text"
                placeholder="Найти">
        </label>
        <div class="search__icon"
            @click="handleAddFilterValue">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7026 13.7032L17.4995 17.5001M15.625 9.0625C15.625 12.6869 12.6869 15.625 9.0625 15.625C5.43813 15.625 2.5 12.6869 2.5 9.0625C2.5 5.43813 5.43813 2.5 9.0625 2.5C12.6869 2.5 15.625 5.43813 15.625 9.0625Z" stroke="#8897AE" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "SearchInput",
    setup(){
        let value = ref('');
        return{
            value
        }
    },
    emits: ['addFilterValue', 'removeFilterValue'],
    methods: {
        handleAddFilterValue() {
            this.$emit('addFilterValue', this.value);
        },
        removeFilter(){ 
            this.value = '';
            this.$emit('removeFilterValue', this.value);
        },
    }
}
</script>

<style scoped>
.search{
    width: 100%;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background-color: var(--color-base-00);
    border: 1px solid var(--color-state-grey-200);
    border-radius: 10px;
}
.search__panel{
    flex: 1;
}
.search__icon{
    margin-left: 16px;
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
.search__icon:hover path{
    stroke: var(--color-primary-blue-500);
}
.search-input{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    color: var(--color-state-grey-600);
}
</style>
