<template>
    <div class="iarea">
        <div v-if="label"
        class="iarea__label font-paragraph-5-medium"
        :class="disabled ? 'iarea__label_disabled' : ''">
            {{ label }}
        </div>
        <textarea
            @input="handleInput"
            @change="handleChange"
            :value="items"
            class="iarea__field font-paragraph-5-medium"
            :disabled="disabled"  
            :placeholder="placeholder"
            >
        </textarea>
        <div v-if="info"
            class="iarea__info font-paragraph-5-regular">
            {{ info }}
        </div>
    </div>
    
</template>

<script>
import {ref} from 'vue';
export default {
    name: "TextArea",
    props: {
        disabled: {
            required: false
        },
        label: {
            required: false,
            type: String,
            default: ''
        },
        info: {
            required: false,
            type: String,
            default: ''
        },
        items: String,
        placeholder: {
            type: String,
            default: '',
            required: false
        }
    },
    setup(props){
        let value = ref(props.value);
        return{
            value
        }
    },
    emits: ['change', 'input', 'update:items'],
    methods:{
        handleChange(event){
            this.value = event.target.value;
            this.$emit('change', event.target.value);
            this.$emit('update:items', event.target.value)
        },
        handleInput(event){
            this.value = event.target.value;
            this.$emit('input', this.value);
            this.$emit('update:items', event.target.value)
        }
    }
}
</script>

<style scoped>
.iarea{
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: fit-content;
    width: 100%;
}
.iarea__label, .iarea__info{
    min-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.iarea__field{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border-radius: 6px;
    background-color: var(--color-base-00);
    border: 1px solid var(--color-state-grey-200);
    color: var(--color-state-grey-900);
    width: 100%;
    padding: 4px 14px;
    height: 100%;
    min-height: 32px;
}
.iarea__label_disabled{
    color: rgba(175, 186, 202, 1);
}
.iarea__field:disabled{
    border: 1px solid rgba(215, 223, 233, 1);
    color: rgba(175, 186, 202, 1);
    cursor: not-allowed;
}
</style>