<template>  
    <div class="collection">
        <div class="collection__header">
            <div class="collection__header__marker_open-close" @click="handleOpenCloseCollection">
                <icon-button :styles="{
                    type: 'Tertiary',
                    size: 'tiny-fix',
                }">
                    <template v-slot="{ fill }">
                        <svg class="svg-container-fix" :class="{ 'rotated': isCollectionOpen }" width="16" height="16"
                            viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L11 8L6 13" :stroke=fill stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
            </div>
            <div class="collection__header__label collection-title">
                {{ objCollection.label }}
            </div>
            <div class="collection__header__add-elem">
                <div class="collection__header__add-elem_counter font-paragraph-6-regular">
                    {{ countItemsInCollection }}
                </div>
                <div v-if="!isLoadingToAddElement" @click="handleAddItemInCollection"
                    class="collection__header__add-elem_button collection-manage-button collection-add-item-button">
                    <div class="svg-container">
                        <svg width="9" height="9" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 8H14.875M8 1.125V14.875" stroke="var(--color-base-00)"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </svg>
                    </div>
                    <tooltip-single-line class="tooltip-to-add-button" :text="'Добавить элемент'"
                        :positionMarker="'left-side'">
                    </tooltip-single-line>
                </div>
                <div v-else class="loading-add-element">
                    <div class="svg-container-add-element">
                        <svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 14V12M12.2426 12.2426L10.8284 10.8284M3.75736 12.2426L5.17157 10.8284M14 8H12M2 8H4M12.2426 3.75736L10.8284 5.17157M3.75736 3.75736L5.17157 5.17157M8 2V4"
                                stroke="#5E718D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="this.isCollectionOpen" class="collection__elements">
            <div v-for="itemCollection, index in objCollection.items" :key="this.generateUUID(itemCollection)"
                class="collection__elements__item">
                <div class="collection__elements__item__panel manage-collection-attributes">
                    <div class="collection__elements__item__panel__size-delete-but"
                        v-if="true">
                        <div class="collection__elements__item__panel__size-delete-but__delete-item
                            collection-manage-button collection-delete-item-button"
                            @click="handleDeleteItemInCollection(itemCollection, index)">
                            <div class="svg-container">
                                <svg width="9" height="9" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.125 10H16.875" stroke="var(--color-base-00)" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3" />
                                </svg>
                            </div>
                            <tooltip-single-line class="tooltip-to-delete-button" :text="'Удалить элемент'"
                                :positionMarker="'left-side'">
                            </tooltip-single-line>
                        </div>
                        <div class="collection__elements__item__panel__size-delete-but__size-marker">
                        </div>
                    </div>
                </div>
                <div class="collection__elements__item__attributes">
                    <!-- <div v-if="true" class="collection__elements__item__attributes__attribute
                        header-item-collection-attribute collection-title">
                        {{ itemCollection[0]?.label }}
                    </div> -->
                    <div v-if="true" class="displaying-attributes">
                        <div v-for="attribute in itemCollection" :key="this.generateUUID(attribute)"
                            class="collection__elements__item__attributes__attribute">
                            <draw-attribute :objAttribute="attribute">
                            </draw-attribute>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <m-accept :isModalOpen="this.handlerDeleteItem.isModalAcceptableOpen" :nameAcceptableButton="'Удалить'"
        :nameCancelButton="'Отменить'" :modal="{
            message: 'Уверены, что хотите удалить элемент?',
        }" @exit="handleCancelAcceptDeleteItemInCollection">
    </m-accept>
</template>

<script>
import IconButton from '@/components/UI/buttons/icon-buttons/IconButton.vue';
import TooltipSingleLine from '@/components/UI/tooltips/TooltipSingleLine.vue';
import apiConfig from '@/config/api.config.js';
import MAccept from '@/components/UI/modal-window/MAccept.vue';
import $api from "@/http/index.js";
import guid from '@/myLib/guid.js';
import { ref } from 'vue';
import DrawAttribute from '@/components/systems/systemsBlockSystemInfo/DrawAttribute.vue';
import { mapActions } from 'vuex';
export default {
    name: "CollectionInputs",
    components: {
        IconButton,
        TooltipSingleLine,
        DrawAttribute,
        MAccept
    },
    props: {
        obj: Object
    },
    setup(props) {
        const itemsStateInCollection = ref([]);
        const objCollection = ref(props.obj);
        const apiFiles = ref(apiConfig.API_GATEWAY_FILES);
        const handlerDeleteItem = ref({
            isModalAcceptableOpen: false,
            isDeleteAccepted: false,
            itemToDelete: null,
            itemToDeleteIndex: null
        });
        const isLoadingToAddElement = ref(false);
        let isCollectionOpen = ref(false);
        return {
            objCollection, itemsStateInCollection, apiFiles,
            handlerDeleteItem, isLoadingToAddElement, isCollectionOpen
        }
    },
    computed: {
        countItemsInCollection() {
            return this.objCollection.items.length;
        }
    },
    methods: {
        ...mapActions({
            setMessageError: 'error/setMessageError',
        }),
        handleOpenCloseCollection(){
            this.isCollectionOpen = !this.isCollectionOpen;
            if(this.isCollectionOpen){
                localStorage.setItem(`${this.$route.params.id}|${this.obj.path}|isCollectionOpen`, this.isCollectionOpen);
            } else {
                localStorage.removeItem(`${this.$route.params.id}|${this.obj.path}|isCollectionOpen`, this.isCollectionOpen);
            }
        },
        handleAddItemInCollection() {
            this.isLoadingToAddElement = true;
            const id = this.$route.params.id;
            const path = this.objCollection.path;
            const url = `${this.apiFiles}/Object/AddCollectionElement/${id}/${path}`;
            $api.post(url)
                .then(response => {
                    console.log(response.data);
                    const newItems = response.data.items;
                    console.log(newItems);
                    this.objCollection.items = JSON.parse(JSON.stringify(newItems));
                    this.itemsStateInCollection.push({
                        isItemOpen: true
                    })
                }).catch(error => {
                    this.setMessageError(error);
                }).finally(() => {
                    this.isLoadingToAddElement = false;
                });
        },
        handleDeleteItemInCollection(item, index) {
            this.handlerDeleteItem.itemToDelete = JSON.parse(JSON.stringify(item));
            this.handlerDeleteItem.itemToDeleteIndex = index;
            this.handlerDeleteItem.isModalAcceptableOpen = true;
        },
        handleCancelAcceptDeleteItemInCollection(isDeleteAccepted) {
            this.handlerDeleteItem.isModalAcceptableOpen = false;
            try {
                if (isDeleteAccepted) {
                    this.deleteItemInCollection(this.handlerDeleteItem.itemToDelete, this.handlerDeleteItem.itemToDeleteIndex);
                }
            } finally {
                this.handlerDeleteItem.itemToDelete = null;
                this.handlerDeleteItem.itemToDeleteIndex = null;
            }
        },
        deleteItemInCollection(item, index) {
            const id = this.$route.params.id;
            const path = this.objCollection.path;
            const url = `${this.apiFiles}/Object/DeleteCollectionElement/${id}/${path}/${index}`;
            $api.delete(url,
                {
                    data: item,
                }
            )
                .then(response => {
                    const newItems = response.data.items;
                    this.itemsStateInCollection = this.itemsStateInCollection.toSpliced(index, 1);//переделать
                    this.objCollection.items = JSON.parse(JSON.stringify(newItems));
                }).catch(error => {
                    this.setMessageError(error);
                })
        },
        handleOpenItem(index) {
            this.itemsStateInCollection[index].isItemOpen = !this.itemsStateInCollection[index].isItemOpen;
        },
        generateUUID() {
            return guid.generateUUID();
        }
    },
    mounted() {
        const length = this.objCollection.items.length;
        for (let i = 0; i < length; ++i) {
            this.itemsStateInCollection.push({
                isItemOpen: true
            })
        }
        this.isCollectionOpen = localStorage.getItem(`${this.$route.params.id}|${this.obj.path}|isCollectionOpen`);
    }
}
</script>

<style scoped>
@keyframes loading-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1800deg);
    }
}

.loading-add-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    animation: loading-rotate 5s linear infinite;
    position: relative;
}

.svg-container-add-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.svg-container-fix {
    position: relative;
    /* transform: translateY(1px); */
}

.rotated {
    transform: rotate(90deg);
}

svg {
    transition: transform 0.3s;
}

.collection {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    /* overflow: hidden; */
}

.collection__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 15px;
    border-bottom: 1px solid var(--color-state-grey-100);
    height: 40px;
    width: 100%;
}

.collection__header__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.collection__header__add-elem {
    border: 1px solid var(--color-state-grey-200);
    padding: 2px 4px 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.collection-manage-button {
    position: relative;
    border-radius: 2px;
    padding: 2px;
    width: 16px;
    height: 16px;
}

.collection-add-item-button {
    background-color: var(--color-success-green-500);
}

.collection-add-item-button:hover {
    background-color: var(--color-success-green-600);
}

.collection-delete-item-button {
    background-color: var(--color-state-grey-300);
}

.tooltip-to-add-button {
    display: block;
}

.collection__header__add-elem_button {
    position: relative;
}

.tooltip-to-add-button,
.tooltip-to-delete-button {
    display: none;

}

.collection__header__add-elem_button:hover .tooltip-to-add-button {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(17px, -50%);
    z-index: 100;
}

.collection-delete-item-button:hover>.tooltip-to-delete-button {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(17px, -50%);
    z-index: 9999;
}

.svg-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
}

.collection__elements__item {
    display: flex;
    gap: 8px;
    height: fit-content;
    padding: 0px 0px 0px 15px;
}

.collection__elements__item__panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.collection__elements__item__panel__open-close-but {
    min-height: 40px;
    display: flex;
    align-items: center;
}

.collection__elements__item__panel__size-delete-but {
    margin: 4px 0px;
    min-height: 32px;
    height: 100%;
    position: relative;
    width: 22px;
}

.collection__elements__item__panel__size-delete-but__delete-item {
    position: absolute;
    top: 10px;
    left: 3px;
    z-index: 2;
}

.collection__elements__item__panel__size-delete-but__size-marker {
    position: absolute;
    left: 10.5px;
    height: 100%;
    width: 0px;
    border-right: 0.5px solid var(--color-state-grey-300);
    border-left: 0.5px solid var(--color-state-grey-300);
}

.collection__elements__item__panel__size-delete-but:hover .collection__elements__item__panel__size-delete-but__size-marker {
    border-right: 0.5px solid var(--color-error-red-800);
    border-left: 0.5px solid var(--color-error-red-800);
}

.collection__elements__item__panel__size-delete-but:hover .collection-delete-item-button {
    background-color: var(--color-error-red-800);
}

.header-item-collection-attribute {
    height: 40px;
    display: flex;
    align-items: center;
}

.collection-title {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--color-state-grey-600);
}

.displaying-attributes,
.collection__elements__item__attributes {
    width: 100%;
}
</style>