<template>
    <div class="input-select">
        <div class="input-select__label block-label">
            <name-input 
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="changeObj.label">
            </name-input>
        </div>
        <div class="input-select__field block-field">
            <div class="icon-open-card" v-if="obj.type=='Connection' && this.changeObj.items"
                @click="handleGoToPage"> 
            </div>
            <simple-connection 
                @searching="handleSearching"
                :isLoading="stateConnection.isLoading"
                :obj="changeObj" 
                :state="{
                    disabled: this.isEnumReadonly
                }"
                @saveNewValue="handleSavingChanges"
                @eventHandler="logEvents">
            </simple-connection>
        </div>
    </div>
    
</template>

<script>
import { ref } from 'vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';
import SimpleConnection from '@/components/UI/typesInputs/SimpleConnection.vue';
import ObjectService from '@/services/ObjectService';
import inputsHandler from '@/myLib/inputsHandler.js';
import { mapActions } from 'vuex';
export default {
    name: "ConnectionInput",
    components: {
        NameInput,
        SimpleConnection
    },
    props: {
        obj: {
            type: Object,
            default: () => {}
        },
    },
    setup(props){
        const changeObj = ref(props.obj);
        const stateConnection = ref({
            isLoading: false
        })
        let isEnumReadonly = ref(false);
        return {
            changeObj,
            isEnumReadonly,
            stateConnection
        }
    }, 
    computed:{
        routeActiveTab() {
            return this.$route.params.activeAttributeSystem;
        },
    },
    methods: {
        ...mapActions({
            setError: 'error/setError',
            setMessageError: 'error/setMessageError',
            setStatusMenuState: 'systemInfo/setStatusMenuState',
            setActiveSystemTab: 'systemInfo/setActiveSystemTab',
            fetchActiveSystem: 'systemInfo/fetchActiveSystem',
        }),
        refreshSystem(){
            this.fetchActiveSystem(this.$route.params.id)
            .then((activeSystem) => {
                let pathTab = activeSystem?.tabs
                    .find(
                        (item) =>
                            item.name == this.routeActiveTab
                    )?.path;
                if (!pathTab) {
                    pathTab = activeSystem?.tabs.find(
                        (item) =>
                            item.isActive === true
                    )?.path;
                }
                this.setActiveSystemTab(pathTab);
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        id: this.$route.params.id,
                        activeAttributeSystem: pathTab || 'empty',
                    }
                });
            }).catch(error => {
                this.setMessageError(error);
            });
        },
        handleGoToPage(){
            const id = this.changeObj.items;
            let typeObject = null;
            return this.fetchActiveSystem(id)
            .then((activeSystem) => {
                console.log(activeSystem.typeObject);
                typeObject = activeSystem.typeObject;
                let pathTab = activeSystem?.tabs
                    .find(
                        (item) =>
                            item.name == this.routeActiveTab
                    )?.path;
                if (!pathTab) {
                    pathTab = activeSystem?.tabs.find(
                        (item) =>
                            item.isActive === true
                    )?.path;
                }
                this.setActiveSystemTab(pathTab);
                this.$router.push({
                    name: `${typeObject}Info`,
                    params: {
                        id: id,
                        activeAttributeSystem: pathTab || 'empty',
                    }
                });
            }).catch(error => {
                this.setMessageError(error);
            });
        },
        handleSavingChanges(newValue){
            const value = newValue;
            const id = this.$route.params.id;
            const path = this.obj.path;
            const objToSave = {
                value: value,
                systemGuid: id,
                path: path,
            }
            this.isEnumReadonly = true;
            inputsHandler.fetchSaveChanges(objToSave)
            .catch((error)=>{
                console.error(
                `[${Date.now()}] [Critical] [TextInput:handleSavingChanges] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            })
            .then((response)=>{
                if(this.changeObj.name === 'ProductStatus'
                    || this.changeObj.name === 'OrganizationStatus'
                ){
                    this.refreshSystem();
                }
                this.setStatusMenuState(true);
                if(!response.data?.elements){
                    this.setMessageError({message: `Input | Enum | Отправка нового значения для сохранения | Техническое имя:${this?.obj?.name}| Пояснение: Неправильный формат объекта -> отсутствует поле elements`, objResponse: response.data});
                } else{
                    this.changeObj.elements = JSON.parse(JSON.stringify(response.data.elements));
                }
                this.changeObj.items = newValue;
            })
            .finally(()=>{
                this.isEnumReadonly = false;
            });
        },
        handleSearching(value){
            this.stateConnection.isLoading = true;
            const objToSearch = {
                type: this.obj.typeObject,
                search: value,
                Size: 50
            }
            ObjectService.GetSearchObjects(objToSearch)
            .then(res=>{
                this.changeObj.elements = res.data.map(it=>{
                    return {
                        name: it.id,
                        label: it.FirstValue
                    }
                });
            })
            .catch(error=>this.setMessageError(error))
            .finally(()=>{
                this.stateConnection.isLoading = false;
            });
        },
        logEvents(obj){
            console.log(obj);
        }
    }
}
</script>

<style scoped>
.icon-open-card{
    display: none;
}
.input-select__field{
    position: relative;
}
.input-select__field:hover .icon-open-card{
    cursor: pointer;
    z-index: 2;
    display: block;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    background: url(@/assets/DS-icons/open-card.svg);
    background-size: contain;
    min-width: 16px;
    aspect-ratio: 1;
}
.input-select{
    width: 100%;
    height: max-content;
    display: flex;
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}
.block-field{
    flex: 1;
    min-width: 20px;
}
.block-label{
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
</style>