import {createStore} from "vuex";
import {sandboxModule} from "@/store/sandboxModule.js";
import {systemsCardsModule} from "@/store/systemsCardsModule.js";
import {systemInfoModule} from "@/store/systemInfoModule.js";
import {errorModule} from "@/store/errorModule.js";
import {reportsModule} from "@/store/reportsModule.js";
import {roleAndAuthModule} from "@/store/roleAndAuthModule.js";
export default createStore({
    state: {
        isAuth: false,
    },
    modules: {
        sandbox: sandboxModule,
        systemsCards: systemsCardsModule,
        systemInfo: systemInfoModule,
        error: errorModule,
        reports: reportsModule,
        roleAndAuth: roleAndAuthModule,
    }
})