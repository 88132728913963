<template>
    <div class="name-input">        
        <div class="name-input__value font-paragraph-5-regular">
            {{ label }}
        </div>
        <div v-if="isRequiredToFilling"
            class="name-input__right-icon font-paragraph-5-regular">
*
            <tooltip-area class="tooltip-required-property"
                :text="'Обязательно к заполнению в текущем статусе'"
                :positionMarker="'left-side'">
            </tooltip-area>  
        </div>
    </div>
</template>

<script>
import TooltipArea from '@/components/UI/tooltips/TooltipArea.vue';
export default {
    name: "NameInput",
    components:{
        TooltipArea
    },
    props:{
        label: String,
        isRequiredToFilling:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.name-input{
    width: 100%;
    max-height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 2px;
}
.name-input__right-icon{
    cursor:context-menu;
    user-select: none;
    color: var(--color-error-red-300);
    width: fit-content;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    position: relative;
}
.name-input__value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-state-grey-600);
}
.tooltip-required-property{
    display: none;
    width: 200px;
}
.name-input__right-icon:hover .tooltip-required-property{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(17px, -50%);
    z-index: 100;
}
</style>