<template>
    <div class="pz-loader phelp__content">
        <div class="pz-loader__container">
            <div class="pz-loader__container__left pz-loader__container__puzzle"></div>
            <div class="pz-loader__container__right pz-loader__container__puzzle"></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@keyframes up-down {
	0% {
		transform: translateY(10px);
	}
	50% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(10px);
	}
}
@keyframes down-up {
	0% {
		transform: translateY(-10px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(-10px);
	}
}
.pz-loader__container{
	display: flex;
	justify-content: center;
	align-items: center;
}
.pz-loader__container__left{
	background: url(@/assets/loaders/pz-left.svg);
	background-size: 'contain';
	animation: up-down 2s infinite;
}
.pz-loader__container__right{
	background: url(@/assets/loaders/pz-right.svg);
	background-size: 'contain';
	animation: down-up 2s infinite;
}
.pz-loader__container__puzzle{
	height: 36px;
	width: 28px;
}
</style>