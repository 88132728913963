<template>
    <div class="input-latex">
        <div class="input-textarea__label block-label">
            <name-input 
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="changeObj.label">
            </name-input>
        </div>
        <div class="input-textarea__field block-field">
            <simple-latex :key="JSON.stringify(changeObj)"
                :obj="changeObj" @saveNewValue="handleSavingChanges" @eventHandler="logEvents"
                @downloadPDF="handleDownloadPDF"
                @checkLatexStatus="handleCheckStatusLatex"
                :isLoadingStatus="latexStatus.isLoading"
                :isLoadingPDF="pdf.isLoading">
            </simple-latex>
        </div>
    </div>
</template>

<script>
import SimpleLatex from '@/components/UI/typesInputs/SimpleLatex.vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';
// import IptButton from '@/components/UI/buttons/IptButton.vue';
import apiConfig from '@/config/api.config.js';
import filesFromServe from '@/myLib/filesFromServe';
import { mapActions } from 'vuex';
import { ref } from 'vue';
import $api from "@/http/index.js";
export default {
    name: "LatexCode",
    components: {
        SimpleLatex,
        NameInput,
        // IptButton
    },
    props: {
        obj: {
            label: String,
            items: String,
        },
        index: Array
    },
    setup(props) {
        const changeObj = ref(props.obj);
        const apiFiles = ref(apiConfig.API_GATEWAY_FILES);
        const mapStateCodeToClass = ref({
            "errorService": 'status-technical-error',
            "noneValue" : 'status-no-checking',
            "inProgress": 'status-in-checking',
            "errorFields": 'status-error-fields',
            "errorLatex" : 'status-error-latex',
            "done": 'status-done'
        });
        const mapStateCodeToName = ref({
            "errorService": 'Техническая ошибка',
            "noneValue": 'Не проверялось',
            "inProgress": 'Проверяется',
            "errorFields": 'Ошибка Полей',
            "errorLatex": 'Ошибка LaTeX',
            "done": 'Готово к сборке',
        });
        const pdf = ref({
            isLoading: false,
        });
        const latexStatus = ref({
            isLoading: false,
        })
        const statusCode = ref(props.obj.status);
        const latexCode = ref('');
        return {
            mapStateCodeToClass, mapStateCodeToName, statusCode,
            latexCode, apiFiles, changeObj, pdf, latexStatus
        }
    },
    methods: {
        ...mapActions({
            setError: 'error/setError',
            setMessageError: 'error/setMessageError',
            setStatusMenuState: 'systemInfo/setStatusMenuState',
        }),
        getClassFromStateCode(stateCode) {
            return this.mapStateCodeToClass[stateCode] ? this.mapStateCodeToClass[stateCode] : this.mapStateCodeToClass['errorService'];
        },
        getNameFromStateCode(stateCode) {
            return this.mapStateCodeToName[stateCode] ? this.mapStateCodeToName[stateCode] : this.mapStateCodeToName['errorService'];
        },
        getErrorMessageFromStateCode() {
            return this.obj.error || 'Нет сообщения';
        },
        logEvents(obj) {
            console.log(obj);
        },
        handleSavingChanges(newValue) {
            const value = newValue;
            const id = this.$route.params.id;
            const path = this.obj.path;
            const url = `${this.apiFiles}/Object/PutCode/${id}/${path}`;
            $api.put(
                url, {data: value}
            ).catch((error) => {
                console.error(
                    `[${Date.now()}] [Critical] [Latex] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            })
                .then((response) => {
                    this.setStatusMenuState(true);
                    this.changeObj.items = newValue;
                    this.changeObj.status = response.data.status;
                    this.changeObj.error = response.data.error;
                })
        },
        async handleDownloadPDF(){
            const id = this.$route.params.id;
            const path = this.obj.path;
            const url = `${this.apiFiles}/Object/GetLinkCode/${id}/${path}`;
            this.pdf.isLoading = true;
            try{
                await filesFromServe.downloadFileFromLink(url);
            } catch(e){
                console.error(JSON.stringify(e));
            } finally {
                this.pdf.isLoading = false;
            }
        },
        handleCheckStatusLatex(){
            this.changeObj.status = 'inProgress';
            this.latexStatus.isLoading = true;
            const id = this.$route.params.id;
            const path = this.obj.path;
            const url = `${this.apiFiles}/Object/CheckCode/${id}/${path}`;
                $api.get(url)
                .then(response=>{
                    this.changeObj.status = response.data;
                })
                .catch((error)=>{
                    if(error?.response?.status === 400){
                        this.changeObj.status = error.response.data.Status;
                        this.changeObj.error = error.response.data.ErrorLog;
                        return Promise.resolve();
                    }
                    this.changeObj.status = 'errorService';
                    this.setMessageError(error);
                })
                .finally(()=>{
                    this.latexStatus.isLoading = false;
                })
            }
        },
         
    }
</script>

<style scoped>
.status-technical-error{
    background-color: var(--color-error-red-800);
}
.status-no-checking{
    background-color: var(--color-warning-yellow-500);
}
.status-in-checking{
    background-color: var(--color-primary-blue-500);
}
.status-error-fields{
    background-color: var(--color-error-red-800);
}
.status-error-latex{
    background-color: var(--color-error-red-800);
}
.status-done{
    background-color: var(--color-success-green-600);
}

.typesinputs-latex__title__status {
    display: flex;
    gap: 10px;
    align-items: center;
}

.typesinputs-latex__title__status__color {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.input-latex {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}

.input-latex_buttons {
    width: 100%;
    height: fit-content;
    display: flex;
    padding-right: 20px;
}
.input-latex_buttons__state-pdf{
    display: flex;
    gap: 12px;
    align-items: center;
}
.block-field {
    flex: 1;
    min-width: 20px;
}

.block-label {
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
.typesinputs-latex__title__status{
    justify-content: flex-end;
    white-space: nowrap;
}
.wrap{
    display: flex;
    justify-content: space-between;
}
</style>