<template>
    <div class="maccept">
        <div class="ma-b" v-if="isModalOpen">
        </div>
        <div class="ma-w" v-if="isModalOpen">
            <div class="ma-w__content">
                <div class="ma-w__content__message font-paragraph-3-semibold">
                    {{ modal.message }}
                </div>
                <div class="ma-w__content__panel">
                    <div class="ma-w__content__panel__cancel-button panel-button">
                        <ipt-button @click="handleCloseFalse" :styles="{
                            type: 'Secondary Grey',
                            size: 'sm'
                        }">
                            {{ nameCancelButton }}
                        </ipt-button>
                    </div>
                    <div class="ma-w__content__panel__accept-button panel-button">
                        <ipt-button @click="handleCloseAcceptable" :styles="{
                            type: 'Primary',
                            size: 'sm'
                        }">
                            {{ nameAcceptableButton }}
                        </ipt-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IptButton from '@/components/UI/buttons/IptButton.vue';
import { ref } from 'vue';
export default {
    name: "MAccept",
    components: {
        IptButton,
    },
    props: {
        isModalOpen: Boolean,
        modal: {
            message: String,
        },
        nameAcceptableButton: {
            required: false,
            type: String,
            default: 'Применить'
        },
        nameCancelButton: {
            required: false,
            type: String,
            default: 'Отменить'
        }
    },
    emits: ['exit'],
    setup(props) {
        let isModalActive = ref(props.isModalOpen);
        const objForm = ref(null);
        return {
            isModalActive,
            objForm
        }
    },
    methods: {
        handleCloseFalse() {
            this.$emit('exit', false);
        },
        handleCloseAcceptable() {
            this.$emit('exit', true);
            this.isModalActive = false;
        }
    },
}
</script>

<style scoped>
.panel-button {
    flex: 1;
}

.ma-b {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.3;
}

.ma-w {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    background-color: var(--color-base-00);
    box-shadow: 7px 12px 64px -12px rgba(45, 54, 67, 0.45);
    padding: 24px;
    border-radius: 6px;
}

.ma-w__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
}

.ma-w__content__message {
    color: var(--color-state-grey-900);
    white-space: nowrap;
    width: fit-content;
    white-space: pre-line;
}

.ma-w__content__panel {
    display: flex;
    gap: 12px;
}
</style>