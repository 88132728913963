import router from "@/router/router";
export const errorModule = {
    state: () => ({
        error: {
            isError: false,
        },
        messageError: '',
        statusError: '',
    }),  
    getters: {
        getMessageError(state) {
            return String(state.messageError);
        },
        getStatusError(state) {
            return state.statusError?String(state.statusError):'404';
        },
        getError(state) {
            return state.error;
        },
        getErrorCode(state) {
            return state.error.code;
        },
        getErrorStatus(state) {
            return state.error.status;
        }
    },
    mutations: {
        setError(state, errorObj) {
            for(let i in errorObj) {
                state.error[i] = errorObj[i];
            }
        },
        setMessageError(state, errorMessage){
            state.messageError = String(errorMessage);
        },
        setStatusError(state, errorStatus){
            state.statusError = String(errorStatus);
        }
    },
    actions: {
        setError(state, errorObj) {
            state.commit('setError', errorObj);
        },
        setMessageError({commit}, error){
            try{
                commit('setMessageError', JSON.stringify([error.stack, error.name, error.message, error?.response, {...error}]));
                commit('setStatusError', Number((error?.response?.status || error?.status || 7000)));
                if(error.status == 401 || error?.response?.status == 401) {
                    router.push({name: 'PageAuth'})
                } else {
                    router.push({name: 'PageError'})
                }
            } catch(e) {
                commit('setMessageError', JSON.stringify(e));
                commit('setStatusError', 1000);
            }
        }
    },
    namespaced: true
}