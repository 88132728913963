<template>
    <div v-if="this.objAttribute?.type === 'Input'
        && this.objAttribute.isActive === true 
    ">
        <text-input :obj="this.objAttribute" :index="this.index"></text-input>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Textarea'
        && this.objAttribute.isActive === true ">
        <text-area :obj="this.objAttribute" :index="this.index"></text-area>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Date'
        && this.objAttribute.isActive === true ">
        <date-input :obj="this.objAttribute" :index="this.index"></date-input>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Enum'
        && this.objAttribute.isActive === true ">
        <select-input :obj="this.objAttribute" :index="this.index"></select-input>
    </div>
    <div v-else-if="this.objAttribute?.type === 'File'
        && this.objAttribute.isActive === true ">
        <files-input :obj="this.objAttribute" :index="this.index"></files-input>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Group'
        && this.objAttribute.isActive === true ">
        <group-input :obj="this.objAttribute" :index="this.index"></group-input>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Collection'
        && this.objAttribute.isActive === true ">
        <collection-input :obj="this.objAttribute" :index="this.index"></collection-input>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Code'
        && this.objAttribute.isActive === true ">
        <latex-code :obj="this.objAttribute" :index="this.index"></latex-code>
    </div>
    <div v-else-if="this.objAttribute?.type === 'Connection'
        && this.objAttribute.isActive === true ">
        <connection-input :obj="this.objAttribute" :index="this.index"></connection-input>
    </div>
    <!-- <div v-else-if="this.objAttribute.isActive === true ">
        Неизвестная компонента
        {{ this.objAttribute?.type }}
    </div> -->
</template>
<script>
import TextInput from "@/components/system-inputs/TextInput.vue";
import ConnectionInput from "@/components/system-inputs/ConnectionInput.vue";
import TextArea from "@/components/system-inputs/TextArea.vue";
import DateInput from "@/components/system-inputs/DateInput.vue";
import SelectInput from "@/components/system-inputs/SelectInput.vue";
import FilesInput from "@/components/system-inputs/FilesInput.vue";
import GroupInput from "@/components/system-inputs/GroupInput.vue";
import CollectionInput from "@/components/system-inputs/CollectionInput.vue";
import LatexCode from "@/components/system-inputs/LatexCode.vue";
    export default {
        name: "DrawAttribute",
        components:{
            TextInput,
            TextArea,
            DateInput,
            SelectInput,
            FilesInput,
            GroupInput,
            CollectionInput,
            LatexCode,
            ConnectionInput,
        },
        props: {
            objAttribute: Object,
            index: Array,
        }
    }
</script>

<style scoped>

</style>