<template>
    <div class="input-textarea">
        <div class="input-textarea__label block-label">
            <name-input 
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="changeObj.label">
            </name-input>
        </div>
        <div class="input-textarea__field block-field">
            <simple-textarea 
                :obj="changeObj" 
                @saveNewValue="handleSavingChanges"
                @eventHandler="logEvents">
            </simple-textarea>
        </div>
    </div>
</template>

<script>
import SimpleTextarea from '@/components/UI/typesInputs/SimpleTextarea.vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';
import { mapActions } from 'vuex';
import inputsHandler from '@/myLib/inputsHandler.js';
import {ref} from 'vue';
export default {
    name: "TextArea",
    components: {
        SimpleTextarea,
        NameInput
    },
    props: {
        obj: {
            label: String,
            items: String,
        },
        index: Array
    },
    setup(props){
        const changeObj = ref(props.obj);
        return {
            changeObj
        }
    },
    methods: {
        ...mapActions({
            setError: 'error/setError',
            setMessageError: 'error/setMessageError',
            setStatusMenuState: 'systemInfo/setStatusMenuState',
        }),
        handleSavingChanges(newValue){
            const value = newValue;
            const id = this.$route.params.id;
            const path = this.obj.path;
            const objToSave = {
                value: value,
                systemGuid: id,
                path: path,
            }
            inputsHandler.fetchSaveChanges(objToSave)
            .catch((error)=>{
                console.error(
                `[${Date.now()}] [Critical] [TextArea:handleSavingChanges] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            })
            .then(()=>{
                this.setStatusMenuState(true);
                this.changeObj.items = newValue;
            });
        },
        logEvents(obj){
            console.log(obj);
        },
    }
}
</script>

<style scoped>
.input-textarea{
    width: 100%;
    height: fit-content;
    display: flex;
    /* padding-right: 32px; */
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}
.block-field{
    flex: 1;
    min-width: 20px;
}
.block-label{
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
</style>