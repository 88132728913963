import { createRouter, createWebHistory } from 'vue-router';
// import PageSystemInfo from '@/pages/PageSystemInfo.vue';
// import PageSystemsCards from '@/pages/PageSystemsCards.vue';
// import PageGeneralReports from '@/pages/PageGeneralReports.vue';
// import PageSandbox from '@/pages/PageSandbox.vue';
import PageError from '@/pages/PageError.vue';
import PageSettings from '@/pages/PageSettings.vue';
import PageHelp from '@/pages/PageHelp.vue';
import PageDocsTemplates from '@/pages/PageDocsTemplates.vue';
// import PageFactory from '@/pages/PageFactory.vue';
// import PageAuth from '@/pages/PageAuth.vue';
const routes = [
    { path: '/auth', component: ()=> import('@/pages/PageAuth.vue'), name: 'PageAuth' },
    // { 
    //     path: '/archive', 
    //     component: PageSystemsCards, 
    //     name: 'PageSystemsCardsArchive',
    //     props: { type: 'archive' }
    // },
    // { 
    //     path: '/archive/:id/:activeAttributeSystem?', 
    //     component: PageSystemInfo, 
    //     name: 'PageSystemInfoArchive',
    //     meta:{
    //         parentNamePouter: 'PageSystemsCardsArchive' 
    //     }
    // },
    { path: '/docs-templates', component: PageDocsTemplates, name: 'PageDocsTemplates' },
    { path: '/about', component: PageSettings, },
    { path: '/help', component: PageHelp, },
    { 
        path: '/error/:errorStatus?', 
        component: PageError, 
        name: 'PageError', 
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
